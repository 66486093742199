import { useState } from 'react';
import HistoryCard from '../HistoryCard/HistoryCard';

const HistoryCardsContainer = () => {
  const [histories, setHistories] = useState(JSON.parse(localStorage.getItem('conversations')));
  // const lsHistoricalConversations = JSON.parse(localStorage.getItem('conversations'));

  return (
    <>
      {histories?.map((history) => {
        return (
          <HistoryCard
            item={history}
            setHistories={setHistories}
          />
        )
      })}
    </>
  );
};

export default HistoryCardsContainer;
