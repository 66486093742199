import { useState } from 'react';
import { useForm } from 'react-hook-form';
import Calendar from 'react-calendar';
import moment from 'moment';
import emailjs from '@emailjs/browser';
import { ErrorMessage } from "@hookform/error-message";

import { useModal } from '../../context/modal-context';

import { isValidEmail } from '../../utils/form-validation';

import 'react-calendar/dist/Calendar.css';
import styles from './ScheduleMeeting.module.scss';

const ScheduleMeeting = () => {
  const [date, setDate] = useState(new Date());
  const [screen, setScreen] = useState('date');
  const [selectedTime, setSelectedTime] = useState(null);
  const [showValidationError, setShowValidationError] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { setModalOpen } = useModal();

  const sendEmail = async (data) => {
    try {
      emailjs
        .send('service_5fly1dk', 'template_7xiisrv', data, { publicKey: 'Cd3e3hJE09H5U-Yji' });
      setModalOpen(false);
    } catch {
      console.log("Error whilst sending email!");
    }
  }

  const onSubmit = (formData) => {
    const packagedData = { ...formData, dateTime: `${moment(date).format('MMMM Do YYYY')} ${selectedTime}` }

    sendEmail(packagedData);
  };

  const handleEmailValidation = email => {
    const isValid = isValidEmail(email);

    const validityChanged =
      (errors.email && isValid) || (!errors.email && !isValid);
    if (validityChanged) {
      console.log("Fire tracker with", isValid ? "Valid" : "Invalid");
    }

    return isValid;
  };

  const validateDateTime = () => {
    if (!selectedTime) {
      setShowValidationError(true);
      return;
    }

    setShowValidationError(false);
    setScreen('form');
  }

  if (screen === 'date') {
    return (
      <div className={styles['modal-wrap']}>
        <h3>Schedule a meeting</h3>
        <div className={styles.wrapper}>
          <div className={styles.left}>
            <p>Choose a date</p>
            <Calendar onChange={setDate} value={date} />
          </div>
          <div className={styles.right}>
            <p>Choose a time</p>
            <div className={styles.timeslots}>
              <button onClick={() => setSelectedTime('10:00')} className={selectedTime === '10:00' ? styles.active : ''}>10:00 AM</button>
              <button onClick={() => setSelectedTime('11:00')} className={selectedTime === '11:00' ? styles.active : ''}>11:00 AM</button>
              <button onClick={() => setSelectedTime('12:00')} className={selectedTime === '12:00' ? styles.active : ''}>12:00 PM</button>
              <button onClick={() => setSelectedTime('13:00')} className={selectedTime === '13:00' ? styles.active : ''}>13:00 PM</button>
              <button onClick={() => setSelectedTime('14:00')} className={selectedTime === '14:00' ? styles.active : ''}>14:00 PM</button>
              <button onClick={() => setSelectedTime('15:00')} className={selectedTime === '15:00' ? styles.active : ''}>15:00 PM</button>
              <button onClick={() => setSelectedTime('16:00')} className={selectedTime === '16:00' ? styles.active : ''}>16:00 PM</button>
              <button onClick={() => setSelectedTime('17:00')} className={selectedTime === '17:00' ? styles.active : ''}>17:00 PM</button>
              <button onClick={() => setSelectedTime('18:00')} className={selectedTime === '18:00' ? styles.active : ''}>18:00 PM</button>
            </div>
          </div>
        </div>
        {showValidationError && <span className={styles.error}>Please select a date and time</span>}
        <button className="form-button" type="button" onClick={validateDateTime}>next</button>
      </div>
    )
  }

  return (
    <div className={styles['modal-wrap']}>
      <h3>Schedule a meeting</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <button className={styles.back} onClick={() => setScreen('date')}>Choose a new time</button>
        <div className={styles['selected-date-time']}>{moment(date).format('MMMM Do YYYY')} {selectedTime}</div>
        <label>first name</label>
        <input
          name="firstName"
          autoComplete="off"
          {...register("firstName", {
            required: 'First name is required',
          })}
        />

        <label>last name</label>
        <input
          name="lastName"
          autoComplete="off"
          {...register("lastName", {
            required: 'Lastname is required',
          })}
        />

        <label>email</label>
        <input
          name="email"
          autoComplete="off"
          {...register("email", {
            validate: handleEmailValidation
          })}
        />
        {errors.lastName && <p>Last name is required.</p>}
        <p className="form-tip">Separate multiple addresses with commas</p>
        <label>Is there anything specific you'd like to discuss?</label>
        <textarea
          name="message"
          {...register("message", {
          })} />
        <div className="form-error-message">
          <ErrorMessage errors={errors} name="firstName" message="First name is required" />
        </div>
        <div className="form-error-message">
          <ErrorMessage errors={errors} name="lastName" message="Lastname is required" />
        </div>
        <div className="form-error-message">
          <ErrorMessage errors={errors} name="email" message="A valid email address is required" />
        </div>
        <button className="form-button" type="submit">Send email</button>
      </form>
    </div>
  )


}

export default ScheduleMeeting;