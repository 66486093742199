import { useState, useRef, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import ScheduleMeeting from '../ScheduleMeeting/ScheduleMeeting';
import { useModal } from '../../context/modal-context';
import { Globe, History, Bookmark, Calendar } from '../Icons';
import useOutsideAlerter from '../../hooks/useOutsideAlerter';
import styles from './MobileNavigation.module.scss';

const MobileNavigation = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const subNavRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { openModalSetData } = useModal();
  const clickedOutside = useOutsideAlerter(subNavRef, () => setShowDropdown(false));

  const homeToHistory = () => {
    navigate('/history', { state: { ref: 'home' } });
  }

  useEffect(() => {
    if (clickedOutside) clickedOutside();
  }, [clickedOutside]);

  const handleOptionClick = () => {
    setShowDropdown(false);
  }

  let hamburgerColBlack = false;

  if (
    location.pathname === '/history' ||
    location.pathname === '/my-saved' ||
    location.pathname === '/chat') {
    hamburgerColBlack = true;
  }

  return (
    <div className={styles['nav-wrapper']}>
      <div className={styles['nav-inside-wrapper']}>
        <div className={styles.menu} onClick={() => setShowDropdown(!showDropdown)}>
          <div className={`${styles.bar1} ${hamburgerColBlack ? styles.black : ''}`}></div>
          <div className={`${styles.bar2} ${hamburgerColBlack ? styles.black : ''}`} ></div>
        </div>
        <ul className={styles.nav}>
          <li ref={subNavRef}>
            <div className={`${styles['extra-menu']} ${!showDropdown ? 'hide' : ''}`}>
              <ul className={styles['extra-menu-ul']}>
                <li onClick={handleOptionClick}>
                  <Link to="/" className={styles['sub-item']}>
                    <span className={styles.icon}>
                      <Globe fill="#fff" />
                    </span>
                    Home
                  </Link>
                  <li>
                    {location.pathname !== '/'
                      ?
                      (
                        <Link to="/history" className={styles['sub-item']}>
                          <span className={styles.icon}>
                            <History fill="#fff" />
                          </span>
                          <span className="letter-spacing">History</span>
                        </Link>
                      ) : (
                        <div>
                          <a className={styles['sub-item']} onClick={homeToHistory}><span className="letter-spacing">History</span></a>
                        </div>
                      )}
                  </li>
                  <Link to="/my-saved" className={styles['sub-item']}>
                    <span className={styles.icon}>
                      <Bookmark fill="#fff" />
                    </span>
                    <span className="letter-spacing">My</span>
                    &nbsp;
                    <span className="letter-spacing">saved</span>
                  </Link>
                  <Link className={styles['sub-item']} onClick={() => openModalSetData(<ScheduleMeeting />)}>
                    <span className={styles.icon}>
                      <Calendar fill="#fff" />
                    </span>
                    <span className="letter-spacing">Schedule</span>
                    &nbsp;
                    <span className="letter-spacing">a</span>
                    &nbsp;
                    <span className="letter-spacing">meeting</span>
                  </Link>
                  <Link to="/chat?id=new" className={styles['sub-item']}>
                    <span className={styles.icon}>
                      <Calendar fill="#fff" />
                    </span>
                    <span className="letter-spacing">Start</span>
                    &nbsp;
                    <span className="letter-spacing">a</span>
                    &nbsp;
                    <span className="letter-spacing">new</span>
                    &nbsp;
                    <span className="letter-spacing">thread</span>
                  </Link>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default MobileNavigation;