import { useRef, useEffect, useState } from 'react';
import styles from './TabsPresenter.module.scss';
import classnames from 'classnames';

export function TabsPresenter ({ tabData, selected, onClick, large, className }) {
    const isSelected = (item) => selected === item && 'active'
    const selectedRef = useRef(null);
    const [tabBar, setTabBar] = useState();

    const drawTabBar = () => {
        if (!selectedRef) return;
        const barStyle = {
            width: `${selectedRef?.current?.offsetWidth}px`,
            left: `${selectedRef?.current?.offsetLeft}px`
        };
        return <div className={styles.tabBar} style={barStyle} />;
    }

    const onDrawTabs = () => {
        return tabData.map((item, i) => {
            const itemProps = selected === i ? { ref: selectedRef } : {};
            return (
                <div
                    {...itemProps}
                    key={item.label}
                    onClick={() => onClick(i)}
                    className={classnames({
                        [styles.tab]: true, 
                        [styles.active]: isSelected(i),
                    })
                    }
                >
                    <span className={classnames({
                        [styles.large]: large ? true : false
                    })
                    }>{item.label}</span><sup>{item.count}</sup>
                </div>
            )
        })
    }

    useEffect(() => {
        setTabBar(drawTabBar());
    }, [selected, large]);

    return (
        <section className={classnames(styles.tabContainer, className)}>
            {onDrawTabs()}
            {tabBar}
        </section>
    )
};