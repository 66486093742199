export function Add({
    stroke = "#fff",
    fill = "#1960AE",
}) {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.5 11C21.5 16.799 16.799 21.5 11 21.5C5.20101 21.5 0.5 16.799 0.5 11C0.5 5.20101 5.20101 0.5 11 0.5C16.799 0.5 21.5 5.20101 21.5 11Z" fill={fill} stroke={fill} />
            <path d="M15.0007 11H7.00073" stroke={stroke} stroke-linecap="round" />
            <path d="M11.001 7V15" stroke={stroke} stroke-linecap="round" />
        </svg>
    );
};
