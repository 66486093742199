import { useState, useRef, useEffect } from "react";
import parse from "html-react-parser";
import { useLocation, useNavigate } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import axios from 'axios';

import { useModal } from '../../context/modal-context';
import { useData } from "../../context/data-context";

import SplitScreen from "../../components/SplitScreen/SplitScreen";
import SearchBox from "../../components/Fields/SearchBox/SearchBox";
import ConversationSlideshow from "../../components/ConversationSlideshow/ConversationSlideshow";
import TiledView from "../../components/TiledView/TiledView";
import { IconButton } from "../../components/Buttons";
import { Tile, Rectangle } from "../../components/Icons";
import { QuestionPanel } from "../../components/Panels/QuestionPanel/QuestionPanel";
import { TabsPresenter } from "../../components/TabsPresenter/TabsPresenter";
import { SwipeButtons } from "../../components/Sliders/SwipeButtons/SwipeButtons";
import ShareModalTemplate from '../../components/ShareModalTemplate/ShareModalTemplate';
import { HorizontalContentSliderFooter } from "../../components/HorizontalContentSlider/HorizontalContentSliderFooter";
import AlwaysScrollToBottom from '../../hooks/useScroll';

import styles from "./Chat.module.scss";

const Chat = () => {
  const [desiredSlideIndex, setDesiredSlideIndex] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [conversationId, setConversationId] = useState(null);
  const [currentConversation, setCurrentConversation] = useState([]);
  const [chatHistory, setChatHistory] = useState([]);
  const navigate = useNavigate();

  const containerRef = useRef(null);
  const {
    conversationData,
    updateConversationData,
    shareSingleItem
  } = useData();

  const { openModalSetData } = useModal();

  const cardCount = conversationData[conversationData?.length - 1]?.content?.length || 0;
  const [view, setView] = useState("tiled");

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const date = new Date();

  useEffect(() => {
    if (!conversationId) {
      const id = urlParams.get("id");

      if (id && id !== "new") {
        setConversationId(id);
        const history = JSON.parse(localStorage.getItem('conversations'));
        const restoredConversation = history?.find(({ conversationId }) => conversationId === id);
        setCurrentConversation(restoredConversation?.conversation);
      } else {
        const convId = date.getTime();
        setConversationId(convId);
        navigate(`/chat?id=${convId}`)
      }
    }
  }, []);

  useEffect(() => {
    if (urlParams.get("id") === "new") {
      const convId = date.getTime();
      setConversationId(convId);
      navigate(`/chat?id=${convId}`);
      setCurrentConversation([]);
    }
  }, [urlParams.get("id")])

  const updateCitations = () => {
    if (conversationData?.length > 0) {
      const lastConversationContent = conversationData[conversationData?.length - 1].content;
      const newCitations = lastConversationContent.map(contentItem => contentItem.description);

      setCitations(newCitations);
    }
  };

  useEffect(() => {
    updateCitations();
  }, [conversationData]);

  const getChatItemTemplate = (question, uniqueId) => {
    return {
      "timestamp": new Date().toISOString(),
      "chat_history": chatHistory,
      "conversation_id": (conversationId || uniqueId).toString(),
      "user_id": "sjr",
      "query": question,
      "language_model": {
        "model_version": "gpt4"
      },
      "embedding_model": {
        "model_version": "ada2"
      },
      "max_generated_tokens": 250,
      "top_docs": 5
    }
  }

  const fetchAnswer = async (question) => {
    const data = getChatItemTemplate(question, conversationId)
    const headers = {
      'api-key': '57dcea54-a3c6-4303-8215-11e457dc77a0',
    };

    const url = "https://secure-api.francecentral.cloudapp.azure.com/generate-answers";
    const response = await axios.post(url, data, {
      headers: headers
    });
    return response.data;
  }

  const onSubmit = async (question) => {
    setCurrentConversation([
      ...currentConversation,
      {
        conversation: {
          query: question, response: "SHOWLOADINGSPINNER"
        }
      }
    ]);

    try {
      const response = await fetchAnswer(question);

      setChatHistory([
        ...chatHistory, {
          timestamp: new Date().toISOString(),
          question: response?.conversation?.query,
          answer: response?.conversation?.response
        }
      ]);

      setCurrentConversation([...currentConversation, response]);

      const conversations = localStorage.getItem('conversations');

      if (!conversations) {
        const newData = [{
          conversationId: response.conversation_id,
          conversation: [{
            content: response.content,
            conversation: response.conversation,
            timestamp: new Date().toISOString(),
          }],
        }];

        localStorage.setItem('conversations', JSON.stringify(newData));
      } else {
        const existingConversations = JSON.parse(localStorage.getItem('conversations'));
        const existingConversation = existingConversations.find((item) => parseInt(item.conversationId) === conversationId);

        if (typeof existingConversation === 'object' && parseInt(existingConversation.conversationId) === parseInt(conversationId)) {
          const otherConversations = existingConversations.filter((item) => parseInt(item.conversationId) !== parseInt(conversationId));
          existingConversation.conversation.push(({
            content: response.content,
            conversation: response.conversation,
            timestamp: new Date().toISOString(),
          }));

          const newData = [...otherConversations, existingConversation];
          localStorage.setItem('conversations', JSON.stringify(newData));
        } else {
          const newData = {
            conversationId: response.conversation_id,
            conversation: [{
              content: response.content,
              conversation: response.conversation,
              timestamp: new Date().toISOString(),
            }]
          };

          existingConversations.push(newData);
          localStorage.setItem('conversations', JSON.stringify(existingConversations));
        }
      }

      setView("tiled")
      setDesiredSlideIndex(0);
    } catch (e) {
      setCurrentConversation([
        ...currentConversation,
        {
          conversation: {
            query: question, response: "I'm sorry, something went wrong. Please try again."
          },
          content: [],
        }
      ]);
    }
  }

  const onTileClick = (index) => {
    setView("full");
    setDesiredSlideIndex(index);
  };

  const handleShareConversation = (index, type) => {
    shareSingleItem(index, type);
    openModalSetData(<ShareModalTemplate />);
  };

  const handleUpdateConversation = (question) => {
    updateConversationData(question);
    setView("tiled");
    setDesiredSlideIndex(0);
    containerRef.current.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  };

  useEffect(() => {
    if (cardCount === 2) {
      setView("tiled");
    }
  }, [cardCount]);

  const followUpQuestions = [];

  currentConversation?.forEach(({ conversation }, i) => {
    if (currentConversation?.length === i + 1) {
      conversation?.followup_questions?.forEach((question) => {
        followUpQuestions.push({
          txt: question,
          type: "secondary",
          onClick: () => handleUpdateConversation(question),
        });
      });
    }
  });

  const count = isEmpty(currentConversation)
    ? ""
    : conversationData[0]?.content?.length;

  const tabs = [
    { label: "Conversations" },
    { label: `Content`, count: count || "" },
  ];

  const [citations, setCitations] = useState([]);

  const leftContent = (
    <>
      <div ref={containerRef} className={styles["conversation-container"]}>
        <div className={styles["chat-item"]}>
          {currentConversation?.map(({ conversation }, i) => (
            <QuestionPanel
              conversation={conversation}
              question={conversation?.query}
              handleShareConversation={handleShareConversation}
              index={i}
              answer={{
                content: parse(conversation?.response || conversation.message),
                title: "Generative AI is experimental. Info quality may vary.",
                citations,
              }}
              onCitationClick={onTileClick}
            />
          )
          )
          }
        </div>
        <AlwaysScrollToBottom />
        <div className={styles.chatButtons}>
          {followUpQuestions.length > 0 && (
            <SwipeButtons
              btnVariant="secondary"
              buttons={followUpQuestions}
              onSubmitCallback={onSubmit}
            />
          )}
        </div>
      </div>
      <div className={styles["search-box"]}>
        <SearchBox
          onSubmit={onSubmit}
          showIcon={false}
          placeholder={currentConversation.length > 0 ? "Ask a follow question" : "How can we help?"}
        />
      </div>
    </>
  );

  let rightContent;
  if (
    view === "full" && currentConversation.length > 0 &&
    currentConversation[currentConversation.length - 1]
      .conversation.response !== "SHOWLOADINGSPINNER") {
    rightContent = (
      <>
        <div className={styles.view}>
          <span className={styles.viewText}>View</span>
          <IconButton
            onClick={() => setView("full")}
            className={styles["view-full"]}
          >
            <Rectangle />
          </IconButton>
          <div className={styles['tile-divider']} />
          <IconButton
            inactive
            onClick={() => setView("tiled")}
            className={styles["view-tile"]}
          >
            <Tile />
          </IconButton>
        </div>
        <ConversationSlideshow
          slidesData={currentConversation[currentConversation?.length - 1]?.content}
          desiredSlideIndex={desiredSlideIndex}
          disabled={view === "tiled" || cardCount === 1}
          handleShareConversation={handleShareConversation}
          conversationId={conversationId}
        />
      </>
    );
  } else {
    if (
      currentConversation.length > 0 &&
      Array.isArray(currentConversation[0].content) &&
      // currentConversation?.slice(-1)[0].content.length > 0 &&
      currentConversation[currentConversation?.length - 1]
        .conversation.response !== "SHOWLOADINGSPINNER") {
      rightContent = (
        <>
          <div className={styles.view}>
            <span className={styles.viewText}>View</span>
            <IconButton
              inactive
              onClick={() => setView("full")}
              className={styles["view-full"]}
            >
              <Rectangle />
            </IconButton>
            <div className={styles['tile-divider']} />
            <IconButton
              onClick={() => setView("tiled")}
              className={styles["view-tile"]}
            >
              <Tile />
            </IconButton>
          </div >
          <TiledView
            data={currentConversation[currentConversation?.length - 1].content}
            onTileClick={onTileClick}
          />
          <div className={styles.footer}>
            <HorizontalContentSliderFooter
              disabled
              count={currentConversation.length}
              handleShareConversation={handleShareConversation}
              index={0}
              conversationId={conversationId}
            />
          </div>
        </>
      );
    } else {
      rightContent = <></>;
    }
  }

  return (
    <>
      <TabsPresenter
        tabData={tabs}
        selected={activeTab}
        onClick={setActiveTab}
        className={styles.tabs}
      />
      <SplitScreen
        activeTab={activeTab}
        {...{ leftContent, rightContent }}
        customStyleLeft={{ width: "calc(50% - 76px)" }}
      />
    </>
  );
};

export default Chat;
