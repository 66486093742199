import styles from './IconButton.module.scss';
import classNames from 'classnames';

export function IconButton({
    onClick = (e) => { e.preventDefault()},
    disabled,
    children,
    className = "",
    inactive,
}) {

    return (
        <button
            onClick={onClick}
            disabled={disabled}
            className={classNames({
                [styles.btn]: true,
                [styles.btnDisabled]: disabled,
                [styles.inactive]: inactive,
                [styles.btnIcon]: className === "",
                [className]: true,
            })}>
            {children}
        </button>
    );
};
