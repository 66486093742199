import { useState } from 'react';
import { PropTypes } from "prop-types";

import styles from './Tabs.module.scss';
import classnames from 'classnames';

const Tabs = ({ data, className = "" }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className={classnames(styles.tabs, className)}>
      <ul className={styles['tab-buttons']}>
        <div className={styles['list-container']}>
          {data.map(({ title }, index) => {
            return <li
              key={title?.replace(/\s/g, '')}

              onClick={() => setActiveTab(index)}>
              <span className={classnames({
                [styles.tabItem]: activeTab !== index,
                [styles.active]: activeTab === index,
                [styles.button]: true,
              })}>
                {title}
              </span>
            </li>
          })}
        </div>
      </ul>
      <div className={styles['tab-content']}>
        {data.map(({ content }, index) => {
          return <div key={`tab-content-${index}`} className={`${activeTab === index ? styles.show : ''} ${styles.item}`}>{content}</div>
        })}
      </div>
    </div>
  );
};

Tabs.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired
    }).isRequired
  ).isRequired
}

export default Tabs;