export function Expand({
    fill = "none",
}) {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M21.55 10.9999C21.55 16.8264 16.8266 21.5498 11 21.5498C5.17339 21.5498 0.45 16.8264 0.45 10.9999C0.45 5.17326 5.17339 0.449878 11 0.449878C16.8266 0.449878 21.55 5.17326 21.55 10.9999Z" stroke="#010307" stroke-width="0.9" />
            <path d="M16.4927 5.99987C16.4927 5.71987 16.272 5.49289 15.9998 5.49289L11.5635 5.49289C11.2912 5.49289 11.0705 5.71987 11.0705 5.99987C11.0705 6.27986 11.2912 6.50685 11.5635 6.50685L15.5069 6.50685L15.5069 10.5627C15.5069 10.8427 15.7276 11.0696 15.9998 11.0696C16.272 11.0696 16.4927 10.8427 16.4927 10.5627L16.4927 5.99987ZM12.4572 10.3604L16.3483 6.35836L15.6512 5.64138L11.7601 9.64347L12.4572 10.3604Z" fill="#010307" />
            <path d="M5.5068 16.2835C5.5068 16.5635 5.72749 16.7905 5.99973 16.7905L10.436 16.7905C10.7083 16.7905 10.929 16.5635 10.929 16.2835C10.929 16.0035 10.7083 15.7765 10.436 15.7765L6.49265 15.7765L6.49265 11.7207C6.49265 11.4407 6.27196 11.2137 5.99973 11.2137C5.72749 11.2137 5.5068 11.4407 5.5068 11.7207L5.5068 16.2835ZM9.54232 11.9229L5.65118 15.925L6.34827 16.642L10.2394 12.6399L9.54232 11.9229Z" fill="#010307" />
        </svg>
    );
};
