import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import truncate from '../../utils/truncate-string';
import { Delete } from '../Icons';
import { toastNotify } from '../../components/ToastNotification/ToastNotification';

import styles from './HistoryCard.module.scss';

const HistoryCard = ({
  isExpanded,
  style,
  onClick,
  isActive,
  item,
  setHistories
}) => {
  const navigate = useNavigate();

  const removeHistoricalItem = (id) => {
    const history = JSON.parse(localStorage.getItem('conversations'));
    const updatedHistory = history.filter(({ conversationId }) => conversationId !== id);
    setHistories(updatedHistory);
    localStorage.setItem('conversations', JSON.stringify(updatedHistory));
    toastNotify('error', "Conversation deleted from history");
  }

  const restoreHistoricalConversation = () => {
    navigate(`/chat?id=${item.conversationId}`);
  }

  return (
    <div className={`${styles['card-wrapper']} ${isActive ? styles.active : ''}`} style={style} onClick={(onClick)}>
      <div className={styles['inside-wrapper']}>
        <div className={styles.question}>
          <span onClick={restoreHistoricalConversation}>
            <img className={styles['expand-conversation']} src="assets/svg/expand-blue.svg" width="22" alt="expand" />
          </span>
          {truncate(item.conversation[0]?.conversation?.query, 55)}
        </div>
        <div className={isExpanded ? styles.expanded : ''}>
          <span className={styles.answer}>{truncate(item.conversation[0]?.conversation?.response, 120)}</span>
        </div>
        <div className={styles.timestamp}>{moment(item.conversation[0]?.timestamp).format('MMMM Do YYYY')}</div>
      </div>
      <div className={styles.icons}>
        <span className={styles.icon} onClick={() => removeHistoricalItem(item.conversationId)}><Delete /></span>
      </div>
    </div>
  );
}

export default HistoryCard;
