import { useState } from 'react';

import { useModal } from '../../../context/modal-context';
import { Add } from '../../../components/Icons';
import Card from '../../../components/Card/Card';

import styles from './ContentTabData.module.scss';

const ContentTabData = ({
  savedData,
  updatedSavedItems,
  removeSavedItem
}) => {
  const { openModalSetData } = useModal();
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleHoverIcon = (index) => {
    setHoveredIndex(index);
  }

  const isHovered = (index) => {
    return hoveredIndex === index;
  }

  const expandedContent = ({ src, type, title }) => {
    if (type === 'html') {
      return (
        <>
          <h3>{title}</h3>
          <iframe className={styles.item} src={src} title={title} />
        </>
      );
    } else if (type === 'pdf') {
      return (
        <>
          <h3>{title}</h3>
          <object className={styles.item} data={`${src}#toolbar=0&navpanes=0&view=Fit`} type="application/pdf">
            <embed src={`${src}#toolbar=0&navpanes=0&view=Fit`} type="application/pdf" />
            <p>Your web browser doesn't have a PDF plugin.
              Instead, you can <a href={src} rel="noreferrer" target="_blank">click here to
                download the PDF file.</a></p>
          </object>
        </>
      );
    } else if (type === 'video') {
      return (
        <>
          <h3>{title}</h3>
          <video controls className={styles.item}>
            <source src={src} type="video/mp4" />
          </video>
        </>
      );
    }
  }

  return (
    <div className={styles.wrapper}>
      {savedData.content.map((item, index) => (
        <Card key={index} content={(
          <div
            className={styles['item-template']}
            onMouseOver={() => handleHoverIcon(index)}
            onMouseOut={() => setHoveredIndex(null)}
          >
            <img
              className={styles.expand}
              src="assets/svg/expand-white.svg"
              width="22"
              onClick={() => openModalSetData(() => expandedContent(item))}
              alt="expand"
            />
            <div className={styles.image} style={{ backgroundImage: `url(${item.thumb})` }} />
            <p>{item.description}</p>
            <div className={styles['action-buttons']}>
              <button className={styles.button} onClick={() => updatedSavedItems(item, 'content')}>
                <Add
                  stroke={isHovered(index) ? '#1960AE' : '#fff'}
                  fill={isHovered(index) ? '#fff' : '#1960AE'}
                />
              </button>
              <img
                className={styles.icon}
                src="assets/svg/delete.svg"
                width="22"
                alt="delete"
                onClick={() => removeSavedItem(index, 'content')}
              />
            </div>
          </div>
        )} width="quarter" />
      ))}
    </div>
  );
};

export default ContentTabData;
