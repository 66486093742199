
export function Rectangle({
    fill = "#FFF",
}) {
    return (
        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="9" height="9" fill={fill} />
        </svg>
    );
};
