export function Share({
    fill = "none",
    stroke = "#fff",
}) {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M21.55 11C21.55 16.8266 16.8266 21.55 11 21.55C5.17339 21.55 0.45 16.8266 0.45 11C0.45 5.17339 5.17339 0.45 11 0.45C16.8266 0.45 21.55 5.17339 21.55 11Z" stroke={stroke} stroke-width="0.9" />
            <path d="M11.1631 6.3148V12.7327" stroke={stroke} stroke-width="0.9" stroke-linecap="round" />
            <path d="M9.12598 7.94442L11.163 6.31479L13.2 7.94442" stroke={stroke} stroke-width="0.9" stroke-linecap="round" />
            <path d="M8.31084 9.92437H7.08911V15.6281H15.2372V9.92437H13.9519" stroke={stroke} stroke-width="0.9" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};
