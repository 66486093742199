const easeOutQuint = [0.23, 1, 0.32, 1];
const easeInQuint = [0.755, 0.05, 0.855, 0.06];

export const introWords = {
  hidden: { opacity: 0, y: 50 },
  visible: {
    opacity: 1,
    y: 0,
  }
}

export const introContainer = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.5,
      staggerChildren: 0.25,
      ease: easeOutQuint,
      type: 'tween',
    }
  }
}

export const letsTalkContainer = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      delay: 2,
      staggerChildren: 0.25,
      ease: easeInQuint,
      type: 'tween',
    }
  }
}

export const tabsContainer = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      delay: 2.5,
      staggerChildren: 0.5,
      ease: easeInQuint,
      type: 'tween',
    }
  }
}