export function RedCross({
    stroke = "#4BB543",
    fill = "#4BB543",
}) {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.5 11C21.5 16.799 16.799 21.5 11 21.5C5.20101 21.5 0.5 16.799 0.5 11C0.5 5.20101 5.20101 0.5 11 0.5C16.799 0.5 21.5 5.20101 21.5 11Z" fill="#B33A3A" stroke="#B33A3A" />
            <path d="M13.8278 8.17152L8.1709 13.8284" stroke="white" stroke-linecap="round" />
            <path d="M8.17078 8.17152L13.8276 13.8284" stroke="white" stroke-linecap="round" />
        </svg>
    );
};
