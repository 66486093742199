export function Calendar({
    stroke = "#010307",
    fill = "none",
    strokeWidth = 0.6,
}) {
    return (
        <svg width="9" height="9" viewBox="0 0 9 9" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M0.507812 1.61288H1.68121H7.09904H8.33357V8.41265H0.507812V1.61288Z" stroke="#010307" stroke-width={strokeWidth} stroke-linecap="round" />
            <path d="M2.1499 0.862762L2.1499 2.29702" stroke={stroke} stroke-width={strokeWidth} stroke-linecap="round" />
            <path d="M4.4209 4.08768L4.4209 6.61592" stroke={stroke} stroke-width={strokeWidth} stroke-linecap="round" />
            <path d="M5.64893 5.35165L3.19335 5.35165" stroke={stroke} stroke-width={strokeWidth} stroke-linecap="round" />
            <path d="M6.6499 0.862762L6.6499 2.29702" stroke={stroke} stroke-width={strokeWidth} stroke-linecap="round" />
        </svg>
    );
};
