export function Like({
    fill = "#006EE8",
}) {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="22" height="22" viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill={fill} stroke="none">
                <path d="M2315 5109 c-800 -83 -1501 -518 -1927 -1196 -487 -774 -516 -1777
        -75 -2578 200 -364 487 -676 841 -913 238 -159 600 -311 876 -366 214 -43 295
        -51 530 -51 236 0 333 10 532 51 880 184 1615 842 1898 1702 92 278 125 491
        125 802 0 301 -32 513 -116 775 -124 390 -330 728 -622 1024 -399 404 -893
        653 -1462 737 -123 18 -478 26 -600 13z m431 -1252 c92 -68 134 -131 154 -232
        11 -57 9 -71 -35 -270 -25 -115 -55 -244 -66 -287 -24 -98 -24 -111 5 -145
        l24 -28 561 -6 560 -6 58 -29 c43 -22 65 -41 87 -78 52 -83 51 -95 -82 -840
        -116 -651 -120 -670 -146 -693 l-28 -24 -606 6 c-831 7 -793 5 -987 66 -88 28
        -175 55 -192 60 l-33 11 0 638 0 637 89 77 c87 74 233 237 252 280 13 31 143
        510 190 703 23 92 48 176 57 186 27 30 74 21 138 -26z m-886 -1812 l0 -825
        -435 0 -435 0 0 825 0 825 435 0 435 0 0 -825z"/>
            </g>
        </svg>
    );
};
