import Button from '../../components/Button/Button';
import CardContainer from '../../components/CardContainer/CardContainer';

const BrowseSjr = () => {
    return (
      <>
        <h1>Testing h1</h1>
        <h2>Testing h2</h2>
        <h3>Testing h3</h3>
        <h4>Testing h4</h4>
        <h5>Testing h5</h5>
        <h6>Testing h6</h6>
        <p>testing paragraph</p>
        <Button type="primary" text="Primary button" onClick={() => console.log('primary clicked')} />
        <Button type="secondary" text="Secondary button" onClick={() => console.log('secondary clicked')} />
        <Button type="tertiary" text="Tertiary button" onClick={() => console.log('tertiary clicked')} />
        <Button type="clear" text="Tertiary button" onClick={() => console.log('tertiary clicked')} />
        <Button type="clear" text="Tertiary icon button" icon="cross-blue" onClick={() => console.log('tertiary clicked')} />
        <br/><br /><br />
        <img src="assets/svg/add.svg" width="30" />
        <img src="assets/svg/calendar-blue.svg" width="30" />
        <img src="assets/svg/check.svg" width="30" />
        <img src="assets/svg/check-blue.svg" width="30" />
        <img src="assets/svg/close.svg" width="30" />
        <img src="assets/svg/delete.svg" width="30" />
        <img src="assets/svg/dislike.svg" width="30" />
        <img src="assets/svg/download.svg" width="30" />
        <img src="assets/svg/expand.svg" width="30" />
        <img src="assets/svg/expand-white.svg" width="30" />
        <img src="assets/svg/expand-blue.svg" width="30" />
        <img src="assets/svg/left.svg" width="30" />
        <img src="assets/svg/right.svg" width="30" />
        <img src="assets/svg/next-blue.svg" width="30" />
        <img src="assets/svg/like.svg" width="30" />
        <img src="assets/svg/save.svg" width="30" />
        <img src="assets/svg/share.svg" width="30" />
        <img src="assets/svg/stop.svg" width="30" />
        <img src="assets/svg/trash.svg" width="30" />
        <img src="assets/svg/voice.svg" width="30" />
        <CardContainer />
      </>
    )
};

export default BrowseSjr;