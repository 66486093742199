export function GreenCheck({
    stroke = "#4BB543",
    fill = "#4BB543",
}) {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="11" cy="11" r="10.5" fill="#4BB543" stroke="#4BB543" />
            <path d="M6 11L10 15L15.5 6" stroke="white" stroke-linecap="round" />
        </svg>
    );
};
