import { useState } from "react";
import * as DOMPurify from 'dompurify';
import classnames from "classnames";
import noop from "lodash/noop";

import { Add, Like, Share } from '../../Icons';
import { IconButton } from '../../Buttons';
import { LinkButton } from "../../Buttons/LinkButton";
import { toastNotify } from '../../../components/ToastNotification/ToastNotification';

import styles from "./QuestionPanel.module.scss";

export function QuestionPanel({
  answer,
  question,
  handleShareConversation,
  conversation,
  index,
  onCitationClick = noop,
}) {
  const [liked, setLiked] = useState(false);
  const [unliked, setUnLiked] = useState(false);

  const updatedSavedItems = (item) => {
    let savedContent;
    if (localStorage.getItem('savedContent')) {
      savedContent = JSON.parse(localStorage.getItem('savedContent'));

      // Satalia don't pass us quesiton/answer IDs or timestamps, temporarily using question/answer comparison, not reliable/ideal
      const exists = savedContent.conversations.some(({ query, response }) =>
        query === item.query && response === item.response);
      if (exists) return;

      savedContent.conversations.push(item);
    } else {
      savedContent = {
        conversations: [item],
        content: [],
      };
    };

    localStorage.setItem('savedContent', JSON.stringify(savedContent));
    toastNotify('success', 'Item saved');

  }

  return (
    <article className={styles.container}>
      <header className={styles.header}>
        <h3>{DOMPurify.sanitize(question)}</h3>
      </header>
      <div className={styles.body}>
        <div className={styles.title}>{answer.title}</div>
        <div className={styles.content}>
          {answer.content === 'SHOWLOADINGSPINNER' ?
            <div className="typing-container">
              <div className="typing">
                <div className="typing__dot" />
                <div className="typing__dot" />
                <div className="typing__dot" />
              </div>
            </div> : <span>{answer.content}</span>}
        </div>
        {answer.content !== 'SHOWLOADINGSPINNER' && (
          <>
            <div className={styles.citations}>
              {answer.citations?.map((item, index) => (
                <div key={index} className={styles.citation}>
                  <span className={styles.cite}>{index + 1} </span>
                  <LinkButton
                    onClick={() => onCitationClick(index)}
                    className={styles.citeBtn}>
                    {item}
                  </LinkButton>
                </div>
              ))}
            </div>
            <div className={styles.actions}>
              <div className={styles.buttons}>
                <IconButton className={styles.btn} onClick={() => updatedSavedItems(conversation)}>
                  <Add />
                </IconButton>
                <IconButton
                  className={styles.btn}
                  onClick={() => handleShareConversation(index, 'conversation')}
                  disable={false}
                >
                  <Share />
                </IconButton>
              </div>
              <div className={styles.buttons}>
                <IconButton
                  onClick={() => {
                    setUnLiked((prev) => !prev);
                    setLiked(false);
                  }}
                  className={classnames({
                    [styles.like]: true,
                    [styles.unlikeBtn]: true,
                    [styles.active]: unliked,
                  })}
                >
                  <Like />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setLiked((prev) => !prev)
                    setUnLiked(false)
                  }}
                  className={classnames({
                    [styles.btn]: true,
                    [styles.like]: true,
                    [styles.active]: liked,
                  })}
                >
                  <Like />
                </IconButton>
              </div>
            </div>
          </>
        )}

      </div>
    </article>
  )
}
