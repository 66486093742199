
const apihost = "https://api.dev.nonprod.audiencebrains.satalia.io";
const apikey = "57dcea54-a3c6-4303-8215-11e457dc77a0";

const NEWLINE_SPLIT_PATTERN = /\r\n|[\r\n]/g;
const NEWLINE_END_PATTERN = /\r\n|[\r\n]$/;

export default class StreamedJsonToObjectTransformer {

    /**
     *
     * @param parseAsJson {boolean} - If set to false, the transformer will not try to parse the JSON objects (for debugging).
     */
    constructor(parseAsJson = true) {
        this.decoder = new TextDecoder();
        this.parseAsJson = parseAsJson;
        this.lastString = '';
        this.chunkIndex = 0;
    }

    transform(chunk, controller) {
        // console.debug("Received chunk no", this.chunkIndex++, ", incomplete:", this.lastString);

        const joinedChunk = this.lastString + this.decoder.decode(chunk);
        const lines = joinedChunk.split(NEWLINE_SPLIT_PATTERN).filter(Boolean);
        // If the chunk ends with a newline, then the last element of lines is a complete JSON object, else it is
        // incomplete and should be stored for the next chunk.
        this.lastString = NEWLINE_END_PATTERN.test(joinedChunk) ? "" : lines.pop();
        for (const line of lines) {
            if (this.parseAsJson) {
                try {
                    controller.enqueue(JSON.parse(line));
                } catch (e) {
                    console.error("Chunk line parsing error:", line, e);
                }
            } else {
                controller.enqueue(line);
            }

        }
    }

    flush(controller) {
        if (this.lastString)
            if (this.parseAsJson) {
                try {
                    controller.enqueue(JSON.parse(this.lastString));
                } catch (e) {
                    console.error("Chunk line parsing error:", this.lastString, e);
                }
            } else {
                controller.enqueue(this.lastString);
            }
    }
}