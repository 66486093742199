import { useNavigate, useLocation } from "react-router-dom";

import SplitScreen from '../../components/SplitScreen/SplitScreen';
import { useData } from '../../context/data-context';
import HistoryCardsContainer from '../../components/HistoryCardContainer/HistoryCardsContainer';

import styles from './History.module.scss';

const History = () => {
  const location = useLocation();
  const {
    savedData,
  } = useData();
  const navigate = useNavigate();

  const leftContent = (
    <>
      <h1 class={styles.title}>My History</h1>
      <div className={styles['wrapper']}>
        <HistoryCardsContainer data={savedData} />
      </div>
    </>
  );

  const ref = location?.state?.ref;

  const conversationsHistory = JSON.parse(localStorage.getItem('conversations'));
  const lastConversationId = conversationsHistory?.slice(-1);

  let chatLink = '/chat';
  if (conversationsHistory) {
    chatLink = `/chat?id=${lastConversationId[0]?.conversationId}`
  }

  const rightContent = (
    <>
      {!ref && (
        <button className={styles['back-to-exp']} onClick={() => navigate(chatLink)}>
          Back to Experience
        </button>
      )}
    </>
  );

  return (
    <SplitScreen {...{ leftContent, rightContent, hideRight: true }} />
  )
};

export default History;