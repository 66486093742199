
import { noop } from 'lodash';
import classnames from 'classnames';
import { toastNotify } from '../../components/ToastNotification/ToastNotification';

import { Pagination } from '../Pagination/Pagination';
import { IconButton } from '../Buttons';
import { Add, Share } from '../Icons';

import styles from './HoriziontalContentSlider.module.scss';

export function HorizontalContentSliderFooter({
  count,
  onNext = noop,
  onPrev = noop,
  disabled,
  currentPage,
  handleShareConversation,
  index,
  conversationId
}) {
  const updatedSavedItems = () => {
    const conversations = JSON.parse(localStorage.getItem('conversations'));
    const current = conversations.find((item) => parseInt(item.conversationId) === parseInt(conversationId));
    const currentContentItem = current.conversation.slice(-1)[0].content[currentPage - 1];
    let savedContent;
    if (localStorage.getItem('savedContent')) {
      savedContent = JSON.parse(localStorage.getItem('savedContent'));

      const exists = savedContent.content.some(({ index }) => index === currentContentItem.index);
      if (exists) return;

      savedContent.content.push(currentContentItem);
      toastNotify('success', 'Item saved');
    } else {
      savedContent = {
        conversations: [],
        content: [currentContentItem],
      };
    };

    localStorage.setItem('savedContent', JSON.stringify(savedContent));
  }

  return (
    <div className={styles.bottom}>
      <div className={styles.icons}>
        <IconButton
          className={classnames({
            [styles.btn]: true,
            [styles.btnDisabled]: disabled
          })}
          disabled={disabled}
          onClick={updatedSavedItems}
        >
          <Add fill="#fff" stroke="#1960AE" />
        </IconButton>
        <IconButton
          className={classnames({
            [styles.btn]: true,
            [styles.btnDisabled]: disabled
          })}
          disabled={disabled}
          onClick={(e) => {
            e.preventDefault();
            handleShareConversation(index, 'content')
          }}
        >
          <Share />
        </IconButton>
      </div>
      <div className={styles['scroll-left-right']}>
        <Pagination
          disabled={disabled}
          onPrev={onPrev}
          onNext={onNext}
          count={count}
          current={currentPage}
        />
      </div>
    </div>
  );
};