export function CloseTransparent({
    stroke = "#A7A7A7",
    fill = "none",
    strokeWidth = 0.818182,
}) {
    return (
      <svg width="22" height="22" viewBox="0 0 19 18" fill={fill} xmlns="http://www.w3.org/2000/svg">
        <path d="M18.1363 9C18.1363 13.7446 14.29 17.5909 9.54541 17.5909C4.80078 17.5909 0.954501 13.7446 0.954501 9C0.954501 4.25537 4.80078 0.409091 9.54541 0.409091C14.29 0.409091 18.1363 4.25537 18.1363 9Z" stroke={stroke} stroke-width={strokeWidth} />
        <path d="M11.8588 6.68587L7.23047 11.3142" stroke="#A7A7A7" stroke-width={strokeWidth} stroke-linecap="round"/>
        <path d="M7.23055 6.68587L11.8589 11.3142" stroke="#A7A7A7" stroke-width={strokeWidth} stroke-linecap="round"/>
      </svg>
      
    );
};
