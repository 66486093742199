import { useRef, useState, useEffect } from 'react';
import { PropTypes } from "prop-types";

import HorizontalContentSlider from '../HorizontalContentSlider/HorizontalContentSlider';
import { PdfViewer, HtmlViewer, VideoViewer } from '../Viewers';

import styles from './ConversationSlideshow.module.scss';

const ConversationSlideshow = ({
  slidesData,
  desiredSlideIndex,
  disabled,
  handleShareConversation,
  conversationId
}) => {
  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  function resizeIFrameToFitContent(iFrame) {

    iFrame.width = iFrame.contentWindow.document.body.scrollWidth;
    iFrame.height = iFrame.contentWindow.document.body.scrollHeight;
  }

  window.addEventListener('DOMContentLoaded', function (e) {

    var iFrame = document.getElementById('iFrame1');
    resizeIFrameToFitContent(iFrame);

    // or, to resize all iframes:
    var iframes = document.querySelectorAll("iframe");
    for (var i = 0; i < iframes.length; i++) {
      resizeIFrameToFitContent(iframes[i]);
    }
  });


  const goToSlide = (index) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index);
      setCurrentSlide(index);
    }
  }

  useEffect(() => {
    goToSlide(desiredSlideIndex);
  }, [desiredSlideIndex]);

  return (
    <div className={styles.conversationWrapper}>
      <HorizontalContentSlider
        disabled={disabled}
        forwardRef={sliderRef}
        handleShareConversation={handleShareConversation}
        conversationId={conversationId}
        initialSlide={desiredSlideIndex}>
        {slidesData.map(({ type, ...item }) => {
          if (type === "pdf") {
            return (<PdfViewer {...item} />)
          }

          if (type === "video") {
            return (<VideoViewer {...item} />)
          }

          return (<HtmlViewer {...item} />)
        }
        )}
      </HorizontalContentSlider>
    </div>
  )
}

ConversationSlideshow.propTypes = {
  slidesData: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    src: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    thumb: PropTypes.string.isRequired,
    timestamp: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  })).isRequired,
  desiredSlideIndex: PropTypes.number,
};

export default ConversationSlideshow;
