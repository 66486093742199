import { Link } from 'react-router-dom';
import moment from 'moment';
import { PropTypes } from "prop-types";

import { Add, CloseTransparent } from '../Icons';
import truncate from '../../utils/truncate-string';

import styles from './QuestionAnswerCard.module.scss';

const QuestionAnswerCard = ({
  data,
  i,
  item,
  updatedSavedItems,
  removeSavedItem,
  customStyle,
  truncatable,
  setExpandedCard,
  expandedCard
}) => {

  return (
    <div className={styles['conversation-wrapper']} style={customStyle}>
      <div className={styles['conversation-item']}>
        <div className={styles['question-answer']}>
          {truncatable && <img className={styles['expand-conversation']} src="assets/svg/expand-blue.svg" width="22" alt="expand" onClick={() => setExpandedCard(i)} />}
          {!truncatable && (
            <Link to={`/chat?historicalId=${i}`} className={styles.item}>
              <img className={styles['expand-conversation']} src="assets/svg/expand-blue.svg" width="22" alt="expand" />
              <img className={styles['delete-conversation']} src="assets/svg/delete.svg" width="22" alt="delete" onClick={() => removeSavedItem(i, 'conversations')} />
            </Link>
          )}
          <div className={styles.question}>
            <span>{item.query}</span>
          </div>
          <div className={styles.answer}>
            <span>{expandedCard === i ? item.response : truncate(item.response, 78)}</span>
          </div>
          <div className={styles.timestamp}>{moment(item.timestamp).format('MMMM Do YYYY')}</div>
        </div>
        <div className={styles.icons}>
          <button className={styles.button} onClick={() => updatedSavedItems(item, 'conversation')}>
            <Add />
          </button>
          <button className={styles.button} onClick={() => removeSavedItem(i, 'conversations')}>
            <CloseTransparent />
          </button>
        </div>
      </div>
    </div>
  )
};

QuestionAnswerCard.propTypes = {
  data: PropTypes.object,
  i: PropTypes.number.isRequired,
  updatedSavedItems: PropTypes.func.isRequired,
  removeSavedItem: PropTypes.func.isRequired,
  customStyle: PropTypes.object,
};

export default QuestionAnswerCard;