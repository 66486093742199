import {
  createContext,
  useContext,
  useState,
  useMemo,
} from 'react';

const ModalContext = createContext();

const ModalProvider = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  
  const openModalSetData = (content) => {
    setModalContent(content);
    setModalOpen(true);
  }
  
  const value = useMemo(() => ({
    modalOpen,
        setModalOpen,
        openModalSetData,
        modalContent,
  }), [openModalSetData, modalContent]);

  return (
    <ModalContext.Provider
      value={value}
      {...props}
    />
  );
};

const useModal = () => useContext(ModalContext);

export { ModalProvider, useModal };
