import { useState } from 'react';
import { Expand, CrossBlue } from '../../Icons';
import styles from './SourcesPanel.module.scss';
import classnames from 'classnames';
import { IconButton } from "../../Buttons/IconButton";

export function SourcesPanel({ description, media, onClick }) {
    const [fullscreen, setFullscreen] = useState(false);
    return (
        <section className={styles.panelContainer} onClick={onClick}>
            <div className={classnames({
                [styles.media]: true,
                [styles.mediaExpanded]: fullscreen
            })}>
                <div className={styles.image} style={{ backgroundImage: `url(${media})` }} />
                <IconButton
                    className={styles.toggleBtn}
                    onClick={onClick}>
                    {fullscreen ? <CrossBlue /> : <Expand />}
                </IconButton>
            </div>
            <p className={styles.description}>
                {description}
            </p>
        </section>
    )
}