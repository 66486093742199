export function Globe({
    fill = "none",
    stroke = "#010307",
}) {
    return (
        <svg width="10" height="10" viewBox="0 0 10 10" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <circle cx="4.8335" cy="4.6377" r="4.2" stroke={stroke} stroke-width="0.6" />
            <path d="M6.7334 4.6377C6.7334 5.8457 6.49883 6.92462 6.13299 7.69035C5.7566 8.47812 5.29506 8.8377 4.8834 8.8377C4.47174 8.8377 4.01019 8.47812 3.63381 7.69035C3.26796 6.92462 3.0334 5.8457 3.0334 4.6377C3.0334 3.42969 3.26796 2.35077 3.63381 1.58504C4.01019 0.797266 4.47174 0.437695 4.8834 0.437695C5.29506 0.437695 5.7566 0.797266 6.13299 1.58504C6.49883 2.35077 6.7334 3.42969 6.7334 4.6377Z" stroke={stroke} stroke-width="0.6" />
            <path d="M0.833496 3.1377L8.8335 3.1377" stroke={stroke} stroke-width="0.6" />
            <path d="M0.833496 6.1377H8.8335" stroke={stroke} stroke-width="0.6" />
        </svg>
    );
};
