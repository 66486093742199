import { useState, useEffect } from 'react';
import StreamedJsonToObjectTransformer from '../../utils/transformer';

const StreamTest = () => {
  const [chatStream, setChatStream] = useState([]);

  useEffect(() => {
    getStream();
  }, []);

  const getStream = async () => {
    const streamedJsonToObjectStream = new TransformStream(new StreamedJsonToObjectTransformer());
    const response = await fetch("https://poc.sandbox.audiencebrains.satalia.io/chat/generate?stream=true", {
      method: "POST",
      headers: {
        "Api-Key": "57dcea54-a3c6-4303-8215-11e457dc77a0",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "timestamp": "2023-10-14T20:20:15.252Z",
        "user_id": "cj-id-1",
        "conversation_id": "cj-convo-id-1",
        "query": "Who are SJR?"
      })
    });
    const rs = response.body;
    const jsonStream = rs.pipeThrough(streamedJsonToObjectStream);
    const reader = jsonStream.getReader();
    let concat;
    let arr = [];
    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        console.log('finished', arr);
        return;
      }
      const answer = value?.data?.conversation?.answer;
      arr.push(answer);
      concat = concat + answer;
      concat = concat + answer;
      setChatStream([...arr]);
    }
  }

  return (
    <div>
      <p style={{ color: 'white', fontSize: '14px', padding: '50px' }}>
        {chatStream?.map((word) => word)}
      </p>
    </div>
  )
}

export default StreamTest;

