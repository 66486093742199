import GenericCard from '../GenericCard/GenericCard';
import Card from '../Card/Card';
import dummyOverviewData from '../../data/dummyOverview.json';
import styles from './OverviewTab.module.scss';

const OverviewTab = ({ onClick }) => {
  return (
    <div className={styles['card-container']}>
      {dummyOverviewData.map(({ title, body }) => {
        const content = <GenericCard {...{ title, body }} onClick={() => onClick(title)}  />
        return <Card content={content} width="half" />
      })}
    </div>
  );
}

export default OverviewTab;