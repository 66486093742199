import Home from './pages/Home/Home';
import BrowseSjr from './pages/BrowseSjr/BrowseSjr';
import MySaved from './pages/MySaved/MySaved';
import History from './pages/History/History';
import Chat from './pages/Chat/Chat';
import ChatBot from './pages/ChatBot/ChatBot';
import Animation from './pages/Animation/Animation';
import StreamTest from './pages/StreamTest/StreamTest';
import { Root } from './root'
import './App.scss';
import {
  createBrowserRouter,
} from "react-router-dom";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Root><Chat /></Root>,
  },
  {
    path: "/home",
    element: <Root><Home /></Root>,
  },
  {
    path: "/dashboard",
    element: <Root><Chat /></Root>,
  },
  {
    path: "/browse-sjr",
    element: <Root><BrowseSjr /></Root>,
  },
  {
    path: "/history",
    element: <Root><History /></Root>,
  },
  {
    path: "/chat",
    element: <Root><Chat /></Root>,
  },
  {
    path: "/chatbot",
    element: <Root><ChatBot /></Root>,
  },
  {
    path: "/my-saved",
    element: <Root><MySaved /></Root>,
  },
  {
    path: "/animation",
    element: <Root><Animation /></Root>,
  },
  {
    path: "/streamtest",
    element: <Root><StreamTest /></Root>,
  },
]);