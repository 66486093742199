export function Plus({
    fill = "none",
    stroke = "#000",
    strokeWidth = 0.6,
}) {
    return (
      <svg width="9" height="10" viewBox="0 0 9 10" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path d="M4.56335 1.15961L4.56335 8.84036" stroke={stroke} stroke-width={strokeWidth} stroke-linecap="round" />
      <path d="M8.29364 4.99969L0.83364 4.99969" stroke={stroke} stroke-width={strokeWidth} stroke-linecap="round" />
      </svg>
    );
};
