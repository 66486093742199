import styles from './SearchBox.module.scss';
import noop from 'lodash/noop';
import { SearchField } from "../SearchField/SearchField";
import { SwipeButtons } from '../../Sliders/SwipeButtons/SwipeButtons';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { useState } from 'react';

export function SearchBox({
  onSubmit = noop,
  buttons = [],
  placeholder = "How can we help?",
  swap = false,
  showIcon = true,
  chatBot = false,
}) {
  const [value, setValue] = useState("");
  return (
    <div
      className={classnames({
        [styles.container]: true,
        [styles.chatbot]: chatBot,
      })}
    >
      {!isEmpty(buttons) && (
        <div
          className={classnames({
            [styles.swipe]: true,
            [styles.swipeSwapped]: swap,
          })}
        >
          <SwipeButtons buttons={buttons} />
        </div>)
      }
      <div
        className={classnames({
          [styles.search]: true,
          [styles.searchSwapped]: swap,
        })}
      >
        <SearchField
          value={value}
          chatBot={chatBot}
          onChange={setValue}
          showIcon={showIcon}
          placeholder={placeholder}
          onSubmit={() => {
            onSubmit(value);
            setValue("")
          }}
        />
      </div>
    </div>
  )
}

export default SearchBox;