export function Talk({
  fill = "none",
  stroke = "#1960AE",
}) {
  return (
    <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="19.1915" cy="19.1915" r="18.6915" transform="matrix(-1 0 0 1 38.883 0)" stroke={fill} />
    <path d="M11.3767 31.0702C11.5502 31.0702 11.7228 31.0123 11.8639 30.8991L16.8466 26.9126H29.0462C30.6225 26.9126 31.9044 25.6306 31.9044 24.0544V22.8485C31.9044 22.4182 31.5552 22.069 31.1248 22.069C30.6945 22.069 30.3453 22.4182 30.3453 22.8485V24.0544C30.3453 24.771 29.7627 25.3537 29.046 25.3537H16.5734C16.3968 25.3537 16.2242 25.4141 16.0862 25.5247L12.1559 28.6687V26.1331C12.1559 25.7028 11.8067 25.3536 11.3764 25.3536H10.337C9.62038 25.3536 9.03777 24.771 9.03777 24.0543V11.5817C9.03777 10.8651 9.62038 10.2825 10.337 10.2825H13.4552C13.8855 10.2825 14.2347 9.93329 14.2347 9.50293C14.2347 9.07257 13.8855 8.72339 13.4552 8.72339H10.337C8.76071 8.72339 7.47882 10.0054 7.47882 11.5816V24.0542C7.47882 25.6305 8.76085 26.9124 10.337 26.9124H10.5968V30.2905C10.5968 30.59 10.7683 30.8635 11.0384 30.993C11.146 31.0447 11.2616 31.0701 11.3764 31.0701L11.3767 31.0702Z" fill={fill} />
    <circle cx="14.4574" cy="17.4467" r="1.74468" transform="rotate(180 14.4574 17.4467)" fill={fill} />
    <circle cx="19.6915" cy="17.4467" r="1.74468" transform="rotate(180 19.6915 17.4467)" fill={fill} />
    <circle cx="24.9255" cy="17.4467" r="1.74468" transform="rotate(180 24.9255 17.4467)" fill={fill} />
    </svg>

  );
};
