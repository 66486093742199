import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './Button.module.scss';

export function Button({ 
    onClick, 
    variant, 
    children, 
    disabled, 
    className,
}) {
    return (
        <button
            type="button"
            className={classnames({
                [styles.btn]: true,
                [styles.disabled]: disabled,
                [styles.primary]: true,
                [styles.secondary]: variant === "secondary",
                [styles.tertiary]: variant === "tertiary",
                [styles.tertiaryOutline]: variant === "tertiary-outline",
                [styles.quaternary]: variant === "quaternary",
                [className]: true
            })}
            disabled={disabled}
            onClick={onClick}
        >
            {children}
        </button>
    )
};

Button.propTypes = {
  onClick: PropTypes.func,
  variant: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

Button.defaultProps = {
  onClick: () => {},
  variant: "primary",
  disabled: false,
  width: undefined,
  className: "",
};
