import Card from '../Card/Card';
import GenericCard from '../GenericCard/GenericCard';
import dummySpotlightData from '../../data/dummySpotlight.json';
import styles from './ContentSpotlight.module.scss'

const ContentSpotlight = ({ onClick }) => (
  <div className={styles['card-container']}>
    {dummySpotlightData.map(({ title, body }) => {
      const content = <GenericCard {...{ title, body }} onClick={() => onClick(title)} />
      return <Card content={content} width="half" />
    })}
  </div>
);

export default ContentSpotlight;