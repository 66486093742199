import { PropTypes } from "prop-types";
import styles from './Card.module.scss';

const Card = ({ content, width, keyid }) => {
  return (
    <div key={`card-${keyid}`} className={width === 'quarter' ? styles.quarter : styles.half}>
      {content}

      {/* SHOULD BE DONE THIS WAY NOT WORKING FOR SOME REASON (THROWS ERROR). TO BE INVESTIGATED */}
      {/* {Children.map(children, child => (
        cloneElement(child, {style: {...child.props.style, opacity: 0.5}})
      ))} */}
    </div>
  )
};

Card.propTypes = {
  width: PropTypes.string,
  content: PropTypes.element.isRequired,
  keyid: PropTypes.number,
};

Card.defaultProps = {
  width: "full",
};


export default Card;