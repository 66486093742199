import { PropTypes } from "prop-types";

import { IconButton } from '../Buttons/IconButton';
import { Close } from '../Icons';
import styles from './ShareCard.module.scss';
import classnames from "classnames";

export function ShareCard({ item, width, remove }) {
  return (
    <div className={classnames({ [styles.quarter]: width === 'quarter' })}>
      {item.contentType === 'content' ? (
        <div className={styles['shared-item']}>
          <IconButton className={styles.remove}>
            <button onClick={() => remove(item.id, 'content')}>
              <Close />
            </button>
          </IconButton>
          <div className={styles.image}>
            <img className={styles.img} alt='placeholder' src={item.thumb} />
          </div>
          <div className={styles.answer}>
            {item.description && item.description.substring(0, 180)}
          </div>
        </div>
      ) :
        (
          <div className={styles['shared-item-conversation']}>
            <IconButton className={styles.remove}>
              <button onClick={() => remove(item.id, 'conversation')}>
                <Close />
              </button>
            </IconButton>
            <div className={styles.question}>
              {item.query.substring(0, 35)}
            </div>
            <div className={styles.answer}>
              {item.response.substring(0, 80)}...
            </div>
          </div>
        )
      }
    </div>
  )
};

ShareCard.propTypes = {
  title: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  remove: PropTypes.func.isRequired,
};

ShareCard.defaultProps = {
  width: undefined,
};