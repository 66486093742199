import { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useModal } from '../../context/modal-context';
import ScheduleMeeting from '../ScheduleMeeting/ScheduleMeeting';
import {
  Globe,
  History,
  Bookmark,
  Calendar,
  Plus,
  Chatbot,
} from '../Icons';
import styles from './Navigation.module.scss';

const Navigation = () => {
  const [hoveringOver, setHoveringOver] = useState(null);
  const { openModalSetData } = useModal();
  const navigate = useNavigate();
  const location = useLocation();

  const homeToHistory = () => {
    navigate('/history', { state: { ref: 'home' } });
  }

  const activeStrokeWidth = 1.2;

  if (window.location.href.includes('chatbot')) {
    return <></>
  }

  return (
    <div className={styles['nav-wrapper']}>
      <ul className={styles.nav}>
        <li onMouseOver={() => setHoveringOver('home')} onMouseOut={() => setHoveringOver('')}>
          <Link to="/" className={`${styles.item} ${location.pathname === '/' ? styles.active : ''}`}>
            <span className={styles.icon}>
              <Globe strokeWidth={(location.pathname === '/' || hoveringOver === 'home') && activeStrokeWidth} />
            </span>
            Home
          </Link>
        </li>
        <li onMouseOver={() => setHoveringOver('history')} onMouseOut={() => setHoveringOver('')}>
          {location.pathname !== '/'
            ?
            (
              <Link to={'/history'} className={`${styles.item} ${location.pathname === '/history' ? styles.active : ''}`}>
                <span className={styles.icon}>
                  <History strokeWidth={(location.pathname === '/history' || hoveringOver === 'history') && activeStrokeWidth} />
                </span>
                <span className="letter-spacing">History</span>
              </Link>
            ) : (
              <>
                <span className={styles.icon}>
                  <History strokeWidth={(location.pathname === '/' || hoveringOver === 'home') && activeStrokeWidth} />
                </span>
                <span className="letter-spacing">
                  <a className={`${styles.item} ${location.pathname === '/history' ? styles.active : ''}`} onClick={homeToHistory}>History</a>
                </span>
              </>
            )}
        </li>
        <li onMouseOver={() => setHoveringOver('my-saved')} onMouseOut={() => setHoveringOver('')}>
          <Link to="/my-saved" className={`${styles.item} ${location.pathname === '/my-saved' ? styles.active : ''}`}>
            <span className={styles['adjusted-icon']}>
              <Bookmark strokeWidth={(location.pathname === '/my-saved' || hoveringOver === 'my-saved') && activeStrokeWidth} />
            </span>
            <span className="letter-spacing">My</span>&nbsp;<span className="letter-spacing">Saved</span>
          </Link>
        </li>
        <li onMouseOver={() => setHoveringOver('schedule-a-meeting')} onMouseOut={() => setHoveringOver('')}>
          <Link className={styles.item} onClick={() => openModalSetData(<ScheduleMeeting />)}>
            <span className={styles['adjusted-icon']}>
              <Calendar strokeWidth={hoveringOver === 'schedule-a-meeting' && activeStrokeWidth} />
            </span>
            <span className="letter-spacing">Schedule</span>
            &nbsp;
            <span className="letter-spacing">a</span>
            &nbsp;
            <span className="letter-spacing">meeting</span>
          </Link>
        </li>
        <li onMouseOver={() => setHoveringOver('new-chat')} onMouseOut={() => setHoveringOver('')}>
          <Link to="/chat?id=new" className={styles.item}>
            <span className={styles['adjusted-icon']}>
              <Plus strokeWidth={hoveringOver === 'new-chat' && activeStrokeWidth} />
            </span>
            <span className="letter-spacing">Start</span>
            &nbsp;
            <span className="letter-spacing">a</span>
            &nbsp;
            <span className="letter-spacing">new</span>
            &nbsp;
            <span className="letter-spacing">thread</span>
          </Link>
        </li>
        <li onMouseOver={() => setHoveringOver('chatbot')} onMouseOut={() => setHoveringOver('')}>
          <Link to="/chatbot" className={styles.item}>
            <span className={styles['adjusted-icon']}>
              <Chatbot strokeWidth={(location.pathname === '/streamtest' || hoveringOver === 'streamtest') && activeStrokeWidth} />
            </span>
            <span className="letter-spacing">Chatbot</span>
          </Link>
        </li>
        <li onMouseOver={() => setHoveringOver('chatbot')} onMouseOut={() => setHoveringOver('')}>
          <Link to="/streamtest" className={styles.item}>
            <span className={styles['adjusted-icon']}>
              <Chatbot strokeWidth={(location.pathname === '/streamtest' || hoveringOver === 'streamtest') && activeStrokeWidth} />
            </span>
            <span className="letter-spacing">Streamtest</span>
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default Navigation;