import { useRef, useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./HoriziontalContentSlider.module.scss";
import { HorizontalContentSliderFooter } from "./HorizontalContentSliderFooter";

const HorizontalContentSlider = ({
  children,
  initialSlide = 0,
  disabled = false,
  handleShareConversation,
  conversationId,
}) => {
  const slider = useRef(null);
  const [current, setCurrent] = useState(null);
  const [currentPage, setCurrentPage] = useState(initialSlide + 1);

  const onPrev = () => {
    setCurrentPage((prev) => prev - 1);
    slider?.current?.slickPrev();
  };

  const onNext = () => {
    setCurrentPage((prev) => prev + 1);
    slider?.current?.slickNext();
  };

  useEffect(() => {
    if (slider?.current?.slickGoTo) {
      slider.current.slickGoTo(initialSlide);
      setCurrentPage(initialSlide + 1);
    }
  }, [initialSlide]);


  useEffect(() => {
    setCurrent(children);
  }, [children]);

  const settings = {
    swipeToSlide: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide,
    adaptiveHeight: true,
    lazyLoad: true,
  };

  return (
    <>
      <div className={styles.wrapper}>
        <Slider {...settings} ref={slider}>
          {children}
        </Slider>
      </div>
      <HorizontalContentSliderFooter
        disabled={disabled}
        count={children.length}
        onPrev={onPrev}
        onNext={onNext}
        index={currentPage - 1}
        currentPage={currentPage}
        handleShareConversation={handleShareConversation}
        conversationId={conversationId}
      />
    </>
  );
};

HorizontalContentSlider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HorizontalContentSlider;
