export function ChatBotSubmit() {
  return (
    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="15.5" cy="15" r="15" fill="#006EE8" />
      <path d="M16.4159 9.46015C16.1523 9.19655 15.7249 9.19655 15.4613 9.46015C15.1977 9.72376 15.1977 10.1511 15.4613 10.4147L19.5578 14.5113H9.56123C9.18844 14.5113 8.88623 14.8135 8.88623 15.1863C8.88623 15.5591 9.18844 15.8613 9.56123 15.8613H19.5586L15.4611 19.9615C15.1976 20.2252 15.1977 20.6526 15.4614 20.9161C15.7251 21.1796 16.1525 21.1795 16.416 20.9158L21.6649 15.6634C21.6673 15.661 21.6697 15.6585 21.6721 15.6561C21.8668 15.4553 21.9121 15.1634 21.808 14.9203C21.7785 14.8514 21.737 14.7865 21.6836 14.7286C21.7352 14.7846 21.7775 14.8493 21.808 14.9203" fill="white" />
      <path d="M16.4159 9.46015L21.6626 14.7068L16.4159 9.46015Z" fill="white" />
    </svg>

  );
};
