import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from "@hookform/error-message";

import { useData } from '../../context/data-context';
import { useModal } from '../../context/modal-context';
import { CardScroller, CardScrollerItem } from '../Sliders/CardSlider/CardScroller';
import { ShareCard } from '../ShareCard/ShareCard';
import { isValidEmail } from '../../utils/form-validation';

import styles from './ShareModalTemplate.module.scss';

const ShareModalTemplate = () => {
  const {
    savedConversationContentItem,
    removeShareItem,
  } = useData();

  const { setModalOpen } = useModal();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (savedConversationContentItem.length === 0) {
      setModalOpen(false);
    }
  }, [savedConversationContentItem])

  const onSubmit = (formData) => {
    alert(JSON.stringify(formData))
  };

  const handleEmailValidation = email => {
    console.log("ValidateEmail was called with", email);

    const isValid = isValidEmail(email);

    const validityChanged =
      (errors.email && isValid) || (!errors.email && !isValid);
    if (validityChanged) {
      console.log("Fire tracker with", isValid ? "Valid" : "Invalid");
    }

    return isValid;
  };

  return (
    <div className={styles.wrapper}>
      <h3>Share content</h3>
      <CardScroller>
        {savedConversationContentItem.map((item) => (
          <CardScrollerItem>
            <ShareCard item={item} remove={removeShareItem} />
          </CardScrollerItem>
        ))}
      </CardScroller>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label>email</label>
        <input
          name="email"
          autoComplete="off"
          {...register("email", {
            validate: handleEmailValidation
          })}
        />
        <p className="form-tip">Separate multiple addresses with commas</p>
        <label>Include message</label>
        <textarea
          name="message"
          {...register("message", {
            required: "Message is required",
          })} />
        <div className="form-error-message">
          <ErrorMessage errors={errors} name="email" message="A valid email address is required" />
        </div>
        <div className="form-error-message">
          <ErrorMessage errors={errors} name="message" message="Message is required" />
        </div>
        <button className="form-button" type="submit">Send email</button>
      </form>
    </div>
  );
}

export default ShareModalTemplate;