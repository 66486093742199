import { useState } from 'react';
import styles from './ConversationsTabData.module.scss';
import QuestionAnswerCard from '../../../components/QuestionAnswerCard/QuestionAnswerCard';

const ConversationsTabData = ({
  savedData,
  updatedSavedItems,
  removeSavedItem,
}) => {
  const [expandedCard, setExpandedCard] = useState(-1);

  const handleExpandCardClick = (i) => {
    setExpandedCard(i === expandedCard ? -1 : i);
  }

  // const data = JSON.parse(localStorage.getItem('savedContent'));

  return (
    <div className={styles['conversation-wrapper']}>
      {savedData.conversations.map((item, i) => {
        return (
          <div key={i}>
            <QuestionAnswerCard
              data={savedData}
              i={i}
              item={item}
              updatedSavedItems={updatedSavedItems}
              removeSavedItem={removeSavedItem}
              expandedCard={expandedCard}
              setExpandedCard={handleExpandCardClick}
              truncatable
            />
          </div>
        )
      })}
    </div>
  )
}

export default ConversationsTabData;