import { Link } from 'react-router-dom';

import SearchBox from '../SearchBox/SearchBox';
import Hamburger from '../Hamburger/Hamburger';

import styles from './Header.module.scss';

const Header = () => {
  if (window.location.href.includes('chatbot')) {
    return <></>
  }

  return (
    <div className={styles.wrapper}>
      {/* <Hamburger /> */}
      <div className={styles.header}>
        {/* <div className={styles['logo-wrapper']}>
          <Link to="/">
            <img src="assets/images/SJRLogo.svg" alt="Logo" className="logo" />
          </Link>
        </div> */}
        {/* <div className={styles.nav}>
          <SearchBox placeholder="How can we help?" />
        </div> */}
      </div>
    </div>
  );
}

export default Header;