import { PropTypes } from "prop-types";

import styles from './Button.module.scss';

const Button = ({
  type,
  text,
  onClick,
  icon,
  iconPosition,
  style,
  width,
}) => {
  return (
    <div className={`${styles['button-wrapper']} ${width ? styles[width] : ''}`}  style={style}>
      {icon && <img src={`assets/svg/${icon}.svg`} width="15" className={iconPosition === 'right' ? styles.right : ''} />}
      <button className={styles[type]} onClick={onClick}>{text}</button>
    </div>
  )
};

Button.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  iconPosition: PropTypes.string,
  width: PropTypes.string,
};

Button.defaultProps = {
  type: "primary",
  text: "Submit",
  width: undefined,
};

export default Button;