import Slider from 'react-slick';
import styles from './CardScroller.module.scss';

const SampleNextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    />
  );
}

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

export function CardScrollerItem({ children }) {
  return (
    <div className={styles.item}>
      {children}
    </div>
  );
}

const settingsBasic = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: window.screen.width < 800 ? 1 : 3,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />
};


export const CardScroller = ({ children }) => {
  return (
    <section className={styles.flexWrapper}>
      <div className={styles.sliderContainer}>
        <Slider {...settingsBasic}>
          {children}
        </Slider>
      </div>
    </section>
  );
};
