import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GreenCheck, RedCross } from '../Icons';

import styles from './ToastNotification.module.scss';

const buildTemplate = (type, text) => (
  <div className={`${styles.toast} ${styles[type]}`}>
    {text}
  </div>
);

const toastNotify = (type = 'success', text) => {

  const message = (
    <>
      {type !== 'success' ? <RedCross /> : <GreenCheck />}
      <span className={styles.message}>{text}</span>
    </>
  )
  toast(buildTemplate(type, message));
}

const ToastNotification = () => (
  <ToastContainer
    position="bottom-right"
    autoClose={6000}
    hideProgressBar
  />
);

export { ToastNotification, toastNotify };
