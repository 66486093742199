export function CrossBlue({
    stroke = "#1960AE",
    fill = "none",
}) {
    return (
        <svg width="13" height="13" viewBox="0 0 13 13" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M12.1917 0.999999L1.19165 12" stroke={stroke} stroke-width="1.5" />
            <path d="M1 0.999999L12 12" stroke={stroke} stroke-width="1.5" />
        </svg>
    );
};
