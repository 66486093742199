import React, { useEffect, useState, useRef } from 'react';
import styles from "./VideoViewer.module.scss";
import { Image } from "../../Image/Image";
import classnames from 'classnames';

export function VideoViewer({ src, fallbackText, thumb }) {
    const ref = useRef(null);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (ref?.current) {
            setLoaded(true);
        }
    }, [ref])

    return (
        <div className={styles.videoViewer}>
            <Image
                src={thumb}
                className={classnames({
                    [styles.img]: true,
                    [styles.loading]: loaded
                })}
            />
            <video
                controls
                ref={ref}
                className={classnames({
                    [styles.video]: true,
                    [styles.loading]: !loaded
                })}
            >
                <source src={src} type="video/mp4" />
                {fallbackText}
            </video>
        </div>
    )
};
