import parse from 'html-react-parser';
import { useNavigate } from 'react-router-dom';

import Card from '../Card/Card';
import GenericCard from '../GenericCard/GenericCard';
import { useData } from '../../context/data-context';
import truncate from '../../utils/truncate-string';

import styles from './HistoryTab.module.scss'

const HistoryTab = () => {
  const navigate = useNavigate();
  const { historicalConversations, setConversationData } = useData();
  const formattedHistoryCards = [...historicalConversations].reverse().slice(0, 4);

  const onClick = (index) => {
    setConversationData(formattedHistoryCards[index].history)
    navigate('/chat');
  }

  return (
    <div className={styles['card-container']}>
      {formattedHistoryCards.map((item, index) => {
        const title = item.history[0].conversation.query;
        const body = parse(truncate(item.history[0].conversation.response, 90));
        const content = <GenericCard {...{ title, body, onClick, index }} />
        return <Card content={content} width="half" />
      })}
  </div>
  );
}

export default HistoryTab;