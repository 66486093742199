export function History({
    fill = "none",
    stroke = "#010307",
    strokeWidth = 0.6
}) {
    return (
        <svg width="9" height="11" viewBox="0 0 12 11" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M3.26486 8.4845C3.92348 9.29079 4.84129 9.84377 5.86192 10.0492C6.88254 10.2547 7.94283 10.0999 8.86212 9.6112C9.78141 9.12253 10.5028 8.33023 10.9034 7.3693C11.304 6.40836 11.3591 5.33825 11.0591 4.34129C10.7592 3.34433 10.1229 2.48221 9.25856 1.90183C8.39425 1.32144 7.35543 1.0587 6.31912 1.15837C5.2828 1.25804 4.3131 1.71396 3.57525 2.44844C2.8374 3.18292 2.37704 4.15052 2.27262 5.18637M0.499878 3.5L2.23228 5.42724L4.49988 4.5" stroke={stroke} stroke-width={strokeWidth} />
            <path d="M6.63171 3.5V6L8.62 7.5" stroke={stroke} stroke-width={strokeWidth} />
        </svg>
    );
};
