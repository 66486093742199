import { useState } from "react";

import { CloseChat } from "../../components/Icons";
import SearchBox from "../../components/Fields/SearchBox/SearchBox";
import TypewriterText from "../../components/TypewriterText/TypewriterText";
import AlwaysScrollToBottom from '../../hooks/useScroll';

import StreamedJsonToObjectTransformer from '../../utils/transformer';

import styles from './ChatBot.module.scss';

const ChatBot = () => {
  const [currentConversation, setCurrentConversation] = useState([])
  const [chatHistory, setChatHistory] = useState([]);
  const [followupQuestions, setFollowupQuestions] = useState([]);

  document.getElementById("root").style.backgroundColor = "transparent";

  const handleSubmit = async (question) => {
    setFollowupQuestions([]);
    setCurrentConversation([
      ...currentConversation,
      {
        conversation: {
          query: question, response: "SHOWLOADINGSPINNER"
        }
      }
    ]);
    try {
      const streamedJsonToObjectStream = new TransformStream(new StreamedJsonToObjectTransformer());
      const response = await fetch("https://poc.sandbox.audiencebrains.satalia.io/chat/generate?stream=true", {
        method: "POST",
        headers: {
          "Api-Key": "57dcea54-a3c6-4303-8215-11e457dc77a0",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          "timestamp": "2023-10-14T20:20:15.252Z",
          "user_id": "cj-id-1",
          "conversation_id": "cj-convo-id-1",
          "query": question,
        })
      });
      const rs = response.body;
      const jsonStream = rs.pipeThrough(streamedJsonToObjectStream);
      const reader = jsonStream.getReader();
      let concat;
      let arr = [];

      while (true) {
        const { done, value } = await reader.read();

        if (value.chunk_type === 'follow_ups') {
          setFollowupQuestions(value.data.followup_questions);
          return;
        }

        if (done) return;

        const answer = value?.data?.conversation?.answer;
        arr.push(answer);
        concat = concat + answer;
        concat = concat + answer;

        let formattedResponse = '';
        arr?.forEach((word) => {
          formattedResponse = formattedResponse + word;
        });

        if (value.chunk_type === 'chat') {
          setCurrentConversation([
            ...currentConversation,
            {
              conversation: {
                query: question, response: formattedResponse
              }
            }
          ]);
        }

        if (value.chunk_type === 'chat_finished') {
          setChatHistory([
            ...chatHistory, {
              timestamp: new Date().toISOString(),
              question: question,
              answer: formattedResponse,
            }
          ]);

        }
      }

    } catch (e) {
      console.log('error!', e);
      setCurrentConversation([
        ...currentConversation,
        {
          conversation: {
            query: question, response: "I'm sorry, something went wrong. Please try again."
          },
          content: [],
        }
      ]);
    }
  };

  const renderFollowupQuestions = () => {
    if (currentConversation.length > 0) {
      return followupQuestions?.map((question) => {
        return <button onClick={() => handleSubmit(question)} className={styles.followup}>{question}</button>
      })
    }
  }

  const loadingElement = (
    <div className="typing">
      <div className="typing__dot" />
      <div className="typing__dot" />
      <div className="typing__dot" />
    </div>
  )
  return (
    <div className={styles.wrapper}>
      <div className={styles['chat-container']}>
        <div className={styles.close}>
          <CloseChat />
        </div>
        <div className={styles['conversation-wrapper']}>
          <div className={styles.conversation}>
            {currentConversation?.map(({ conversation }, i) => {
              const { query, response } = conversation;

              return (
                <>
                  <div className={styles.question}>{query}</div>
                  { response === "SHOWLOADINGSPINNER" ?
                      loadingElement :
                    <TypewriterText classNames={styles.answer} text={response} />
                  }

                </>
              )
            })}
            <div className={styles['followup-container']}>{renderFollowupQuestions()}</div>
            <AlwaysScrollToBottom />
          </div>
        </div>
      </div>
      <button className={styles.convert}>Convert</button>
      <div className={styles.input}>
        <SearchBox
          onSubmit={handleSubmit}
          showIcon={false}
          placeholder="Ask a question"
          chatBot={true}
        />
      </div>
    </div>
  );
}

export default ChatBot;
