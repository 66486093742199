import { useState, useEffect } from 'react';
import { useDrag } from 'react-use-gesture';

export function useSwipeDragHome(initialPosition = 0, ref1, ref2, contentUpdated) {
    const [position, setPosition] = useState(initialPosition);
    const [hideFeather, setHideFeather] = useState(true);
    const [lastPosition, setLastPosition] = useState(initialPosition);
    const [isDragged, setIsDragged] = useState(false);
    const [hasMoved, setHasMoved] = useState(false);
    const [containerRef, setContainerRef] = useState(null);
    const [contentRef, setContentRef] = useState(null);
    
    const containerWidth = containerRef && containerRef.current ? containerRef.current.offsetWidth : 0;
    const contentWidth = contentRef && contentRef.current ? contentRef.current.scrollWidth : 0;
    const [isClick, setIsClick] = useState(true);
    
    useEffect(() => {
        if(ref1 && !containerRef){
            setContainerRef(ref1)
        }

        if(ref2 && !contentRef){
            setContentRef(ref2)
        }

    }, [ref1, ref2]);
    
    useEffect(() => {
        const handleMouseUp = () => {
            setIsDragged(false);
        };

        window.addEventListener('mouseup', handleMouseUp);
        window.addEventListener('touchend', handleMouseUp);  // Added touchend event for mobile


        return () => {
            window.removeEventListener('mouseup', handleMouseUp);
            window.removeEventListener('touchend', handleMouseUp);  // Clean up touchend event
        };
    }, []);

    useEffect(() => {
        setPosition(0);
        setLastPosition(0);
        // Execute the desired functionality based on the content update here

    }, [contentUpdated]);  // The effect runs when contentUpdated changes.

    const step = 240; // Define the step size for each button click

    const moveRight = () => {
        let newPosition = position - step;
        const maxLeft = containerWidth - contentWidth;
        //newPosition = Math.max(newPosition, maxLeft);
        const maxRight = 0;
        /* const magicNumber = 30; */
        if (Math.abs(newPosition) >= containerWidth) {
          setHideFeather(false);
        }
        
        newPosition = Math.max(Math.min(newPosition, maxRight), maxLeft);
        setPosition(newPosition);
        setLastPosition(newPosition);
    };

    const moveLeft = () => {
        let newPosition = position + step;
        //newPosition = Math.min(newPosition, 0); // Assuming 0 is the max right position
        const maxLeft = containerWidth - contentWidth;
        const maxRight = 0;
        /* const magicNumber = 30; */
        setHideFeather(true);
        newPosition = Math.max(Math.min(newPosition, maxRight), maxLeft);
        setPosition(newPosition);
        setLastPosition(newPosition);
    };

    

    const bind = useDrag(({ movement: [mx], down, first }) => {
        if (first) setIsDragged(false);

        if (down) {
            if (Math.abs(mx) > 2) {
                setIsDragged(true);
                setHasMoved(true);
            } 

            let newPosition = lastPosition + mx;
            const containerWidth = containerRef.current ? containerRef.current.offsetWidth : 0;
            const contentWidth = contentRef.current ? contentRef.current.scrollWidth : 0;
            const maxLeft = containerWidth - contentWidth;
            const maxRight = 0;
            newPosition = Math.max(Math.min(newPosition, maxRight), maxLeft);
            setPosition(newPosition, mx);
        } else {
            setLastPosition(position);
            if(Math.abs(mx)  > 10){
                setIsClick(false);
            }else{
                setIsClick(true);
            }
            
            
        }
    }, {
        onEnd: () => {
            setHasMoved(false);
            

        }
    });

    

    return { position, isDragged, hasMoved, bind, moveLeft, moveRight, isClick, hideFeather };
}
