import { motion } from "framer-motion";
import styles from './Animation.module.scss';

const Animation = () => {

  // Brian look here https://www.framer.com/motion/transition/
  return (
    <>
      <motion.div
      className={styles.test}
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
      />

      <hr />

      <motion.div
        className={styles.test}
        animate={{
          scale: [1, 2, 2, 1, 1],
          rotate: [0, 0, 270, 270, 0],
          borderRadius: ["20%", "20%", "50%", "50%", "20%"],
        }}
      />

<hr />

<motion.div
  className={styles.test}
  transition={{
    ease: "linear",
    duration: 2,
    x: { duration: 1 }
  }}
/>
    </>
  )
};

export default Animation;