
export function Tile({
    fill = "#FFF",
}) {
    return (
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="1.0">
                <rect width="5.57143" height="5.57143" fill={fill} />
                <rect y="7.42856" width="5.57143" height="5.57143" fill={fill} />
                <rect x="7.42847" width="5.57143" height="5.57143" fill={fill} />
                <rect x="7.42847" y="7.42856" width="5.57143" height="5.57143" fill={fill} />
            </g>
        </svg>
    );
};
