import { PropTypes } from "prop-types";
import noop from "lodash/noop";
import { SourcesPanel } from "../Panels/SourcesPanel/SourcesPanel";

import styles from './TiledView.module.scss';

const TiledView = ({ data, onTileClick = noop }) => (
  <div className={styles.wrapper}>
    <div className={styles.innerWrapper}>
      {data.slice(0, 4).map((item, i) => (
        <SourcesPanel
          onClick={() => onTileClick(i)}
          description={item.description}
          media={item.thumb}
        />)
      )}
    </div>
  </div>
);

TiledView.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    src: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    thumb: PropTypes.string.isRequired,
    timestamp: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  })).isRequired,
  desiredSlideIndex: PropTypes.number,
};

export default TiledView;