import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useModal } from '../../context/modal-context';
import { useData } from '../../context/data-context';

import Tabs from '../../components/Tabs/Tabs';
import ConversationsTabData from './ConversationsTabData/ConversationsTabData';
import ContentTabData from './ContentTabData/ContentTabData';
import ShareModalTemplate from '../../components/ShareModalTemplate/ShareModalTemplate';
import { Share } from '../../components/Icons';

import styles from './MySaved.module.scss';
import last from 'lodash/last';

const MySaved = () => {
  const [savedData, setSavedData] = useState(
    JSON.parse(localStorage.getItem('savedContent')) ||
    { conversations: [], content: [] });

  const {
    updatedSavedItems,
    savedConversationContentItem,
  } = useData();
  const navigate = useNavigate();

  const removeSavedItem = (index, type = 'content') => {
    const { content, conversations } = savedData;

    if (type === 'conversations') {
      conversations.splice(index, 1);
    } else {
      content.splice(index, 1);
    }

    const updatedData = { content: [...content], conversations: [...conversations] };
    localStorage.setItem('savedContent', JSON.stringify(updatedData));
    setSavedData(updatedData);
  }

  const { openModalSetData } = useModal();

  const lastItem = last(savedData?.conversations);
  const all = savedConversationContentItem.map(({ query, response }, index) => ({
    id: lastItem?.id + index + 1,
    query,
    response,
    timestamp: lastItem?.timestamp
  }));
  const conversations = [...savedData.conversations, ...all];

  const tabsData = [{
    id: 1,
    title: `Sources (${savedData?.content.length})`,
    content: <ContentTabData {...{
      savedData,
      updatedSavedItems,
      removeSavedItem,
    }}
    />,
  },
  {
    id: 2,
    title: `Conversations (${savedData.conversations.length})`,
    content: (
      <ConversationsTabData
        savedData={{ ...savedData, conversations }}
        updatedSavedItems={updatedSavedItems}
        removeSavedItem={removeSavedItem}
      />
    ),
  }];

  const conversationsHistory = JSON.parse(localStorage.getItem('conversations'));
  const lastConversationId = conversationsHistory?.slice(-1);

  let chatLink = '/chat';
  if (conversationsHistory) {
    chatLink = `/chat?id=${lastConversationId[0]?.conversationId}`
  }

  return (
    <div className={styles['my-saved-wrapper']}>
      <div className={styles.share}>
        <button
          onClick={() => openModalSetData(
            savedConversationContentItem.length > 0 ?
              <ShareModalTemplate /> :
              <>
                <h3>Share content</h3>
                <p>Please first select content items to share</p>
              </>
          )}>
          <div><Share /></div>
          <span className={styles['share-text']}>Share content</span>
          <span className={styles['saved-count']}>({savedConversationContentItem.length})</span>
        </button>
        <span className={styles['saved-count-mobile']}>{savedConversationContentItem.length}</span>
        <button className={styles['back-to-exp']} onClick={() => navigate(chatLink)}>
          Back to Experience
        </button>
      </div>
      <Tabs data={tabsData} className={styles.tabTitle} />
    </div>
  );
};

export default MySaved;