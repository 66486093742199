export function Chat({
    stroke = "#000",
    fill = "none",
}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="39" viewBox="0 0 40 39" fill={fill}>
            <circle cx="19.5" cy="19.5" r="19" transform="matrix(-1 0 0 1 39.3829 0)" stroke={stroke} />
            <path d="M11.4344 31.5697C11.6107 31.5697 11.7861 31.5108 11.9294 31.3958L16.9922 27.3452H29.3879C30.9896 27.3452 32.2921 26.0426 32.2921 24.4411V23.2158C32.2921 22.7785 31.9373 22.4237 31.5 22.4237C31.0627 22.4237 30.7079 22.7785 30.7079 23.2158V24.4411C30.7079 25.1693 30.1159 25.7612 29.3878 25.7612H16.7146C16.5352 25.7612 16.3599 25.8226 16.2196 25.935L12.2262 29.1295V26.5532C12.2262 26.1159 11.8714 25.7611 11.4341 25.7611H10.378C9.64985 25.7611 9.05787 25.1692 9.05787 24.441V11.7679C9.05787 11.0397 9.64985 10.4478 10.378 10.4478H13.5463C13.9836 10.4478 14.3383 10.093 14.3383 9.65569C14.3383 9.21841 13.9836 8.86362 13.5463 8.86362H10.378C8.77636 8.86362 7.47387 10.1662 7.47387 11.7677V24.4409C7.47387 26.0425 8.7765 27.345 10.378 27.345H10.642V30.7775C10.642 31.0817 10.8163 31.3596 11.0906 31.4912C11.2 31.5438 11.3175 31.5695 11.4341 31.5695L11.4344 31.5697Z" fill="black" />
            <circle cx="14.5649" cy="17.7271" r="1.77273" transform="rotate(180 14.5649 17.7271)" fill="black" />
            <circle cx="19.883" cy="17.7271" r="1.77273" transform="rotate(180 19.883 17.7271)" fill="black" />
            <circle cx="25.2011" cy="17.7271" r="1.77273" transform="rotate(180 25.2011 17.7271)" fill="black" />
        </svg>
    );
};
