import { useEffect, useState } from 'react';
import { motion, useAnimationControls } from 'framer-motion';
import { useNavigate } from "react-router-dom";

import { useData } from '../../context/data-context';
import Tabs from '../../components/Tabs/Tabs';
import SearchBox from '../../components/SearchBox/SearchBox';
import ContentSpotlight from '../../components/ContentSpotlight/ContentSpotlight';
import OverviewTab from '../../components/OverviewTab/OverviewTab';
import HistoryTab from '../../components/HistoryTab/HistoryTab';
import { SwipeButtons } from "../../components/Sliders/SwipeButtons/SwipeButtons";
import {
  introContainer,
  introWords,
  letsTalkContainer,
  tabsContainer
} from './homeAnimations.js';

import styles from './Home.module.scss';

const Home = () => {
  const { setConversationData, conversationData, updateConversationData } = useData();
  const [question, setQuestion] = useState(null);
  const navigate = useNavigate();
  const controls = useAnimationControls();

  const handleOnClick = (title) => {
    setQuestion(title)
    setConversationData([]);
  }

  useEffect(() => {
    handleAnimate();
  }, [])

  useEffect(() => {
    if (conversationData.length === 0 && question !== null) {
      updateConversationData(question);
      navigate('/chat');
    }
  }, [conversationData, question]);

  const tabsData = [{
    id: 1,
    title: 'Overview',
    content: <OverviewTab onClick={handleOnClick} />,
  },
  {
    id: 2,
    title: 'Content spotlight',
    content: <ContentSpotlight onClick={handleOnClick} />,
  },
  {
    id: 3,
    title: 'History',
    content: <HistoryTab />,
  }];

  const intro = "/*Connecting/* Brands/* and/* Audiences/* Through/* Conversation";


  const handleAnimate = () => {
    controls.start('visible');
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        <div className={styles.intro}>
          <motion.h1 variants={introContainer} initial="hidden" animate={controls}>
            {intro.split('/*').map((char, index) => {
              return (
                <motion.span key={char + "-" + index} variants={introWords}>
                  {char}
                </motion.span>
              )
            })}
          </motion.h1>
        </div>
      </div>
      <div className={styles.right}>
        <motion.div className={styles.help} variants={letsTalkContainer} initial="hidden" animate={controls}>
          <SearchBox placeholder="How can we help?" handleNewQuestion={handleOnClick} />
          <div className={styles.swiper}>
            <SwipeButtons
              buttons={[
                { txt: "What does SJR do?", onClick: () => handleOnClick('What does SJR do?') },
                { txt: "Should everyone have a voice in thought leadership?", onClick: () => handleOnClick('Should everyone have a voice in thought leadership?') },
                { txt: "What are your latest case studies?", onClick: () => handleOnClick('What are your latest case studies?') },
                { txt: "Can AI make designers better?", onClick: () => handleOnClick('Can AI make designers better?') }
              ]}
            />
          </div>
        </motion.div>
        <motion.div lassName={styles.content} variants={tabsContainer} initial="hidden" animate={controls}>
          <Tabs data={tabsData} />
        </motion.div>
      </div>
    </div>
  )
};

export default Home;