import Modal from 'react-modal';
import { useModal } from '../../context/modal-context';

import styles from './ModalOverlay.module.scss';

const ModalOverlay = () => {
  const { modalOpen, setModalOpen, modalContent } = useModal();

  return (
      <Modal
        isOpen={modalOpen}
        contentLabel="Example Modal"
        className={styles.modal}
        closeTimeoutMS={300}
      >
        <button className={styles['close-button']} onClick={() => setModalOpen(false)}>Close</button>
        {modalContent}
      </Modal>
  );
}

export default ModalOverlay;