import { useState } from 'react';
import { PropTypes } from "prop-types";
import { useData } from '../../context/data-context';
import { useNavigate } from "react-router-dom";

import { Talk } from '../Icons';

import styles from './SearchBox.module.scss';

const SearchBox = ({ hideIcon, handleNewQuestion }) => {
  const navigate = useNavigate();
  const [question, setQuestion] = useState('');
  const { updateConversationData } = useData();

  console.log('w0t', updateConversationData);

  const handleOnSubmit = (e) => {
    if (!handleNewQuestion) {
      updateConversationData(question);
      setQuestion('');
      e.preventDefault();
      navigate(`/chat?q=${question}`);
    } else {
      handleNewQuestion(question);
      e.preventDefault();
    }
  }

  return (
    <div className={styles['help-input']}>
      {!hideIcon && (
        <div className={styles.talk}>
          <Talk fill="#000" stroke="#000" />
        </div>
      )}
      <form>
        <input className={styles.input} type="text" placeholder="Let's talk!" onChange={({ target }) => setQuestion(target.value)} />
        <div className={styles.buttons}>
          <button type="submit" onClick={(e) => handleOnSubmit(e)} disabled={question.length === 0} className={question.length === 0 ? styles.disabled : ''}>
            <img src="assets/svg/right.svg" width="30" alt="submit" />
          </button>
        </div>
      </form>
    </div>
  )
}

SearchBox.propTypes = {
  hideIcon: PropTypes.bool,
  handleNewQuestion: PropTypes.func,
};

export default SearchBox;