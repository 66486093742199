import noop from 'lodash/noop';
import { ArrowLeft, ArrowRight } from '../Icons';
import styles from './Pagination.module.scss';
import classnames from 'classnames';
import { IconButton } from '../Buttons';

export function Pagination({
  onNext = noop,
  onPrev = noop,
  count = 1,
  current = 1,
  disabled
}) {
  return (
    <div
      className={classnames({
        [styles.pagination]: true,
        [styles.disabled]: disabled
      })}
    >
      <IconButton
        className={classnames({
          [styles.btn]: true,
          [styles.itemDisabled]: disabled
        })}
        onClick={onPrev}
        disabled={current === 1 || disabled}
      >
        <ArrowLeft />
      </IconButton>
      <span
        className={classnames({
          [styles.selection]: true,
          [styles.itemDisabled]: disabled
        })}
      >{`${current}/${count}`}</span>
      <IconButton
        className={classnames({
          [styles.btn]: true,
          [styles.itemDisabled]: disabled
        })}
        onClick={onNext}
        disabled={current === count || disabled}
      >
        <ArrowRight />
      </IconButton>
    </div>

  );
};