import classnames from 'classnames';
import styles from './LinkButton.module.scss';

export function LinkButton({ 
    onClick = () => { }, 
    variant, 
    children, 
    disabled = false, 
    className = ""
}) {
    return (
        <button
            type="button"
            className={classnames({
                [styles.btn]: true,
                [styles.disabled]: disabled,
                [className]: true
            })}
            disabled={disabled}
            onClick={onClick}
        >
            {children}
        </button>
    )
}; 