import { useState, useEffect, createElement } from 'react';

import styles from "./TypewriterText.module.scss";

/* Typewriter text component
 * @param text - The text to display, this can be updated continuously (i.e. a stream)
 * @param type - The element type to display the text inside
 * @param classNames - Any additional classNames to add to the element
 * @param showCursor - Show the blinking cursor or not (animation in CSS)
 * @param typeDelay - Delay between typing each letter
 * @param endDelay - Delay between finishing typing and hiding the cursor
 * */
export function TypewriterText ({
  text = "",
  type = "p",
  classNames = "",
  showCursor = true,
  typeDelay = 25,
  endDelay = 1000
}) {
  const [writtenText, setWrittenText] = useState("");
  const [isDone, setIsDone] = useState(false);

  useEffect(() => {
    const newLength = writtenText.length + 1;

    if (newLength === text.length) {
      setTimeout(() => {
        setIsDone(true);
      }, endDelay);
    }
    
    const timeout = setTimeout(() => {
      setWrittenText(text.slice(0, newLength));
    }, typeDelay);

    return () => clearTimeout(timeout);
  }, [text, writtenText, typeDelay, endDelay]);
  
  const elementClassNames = !isDone && showCursor ? 
    [classNames, styles["blinking-cursor"]].join(" ").trim() :
    classNames.trim();

  return createElement(
    type,
    { className: elementClassNames },
    writtenText
  );
};

export default TypewriterText;
