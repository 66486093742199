import React, { useEffect, useState, useRef } from 'react';
import styles from "./HtmlViewer.module.scss";
import { Image } from "../../Image/Image";
import classnames from 'classnames';

export function HtmlViewer({ src, title, thumb }) {
    const ref = useRef(null);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {        
        if (ref?.current) {            
            setLoaded(true);
        }
    }, [ref])

    return (
        <div className={styles.htmlViewer}>
            <Image
                src={thumb}
                className={classnames({
                    [styles.img]: true,
                    [styles.loading]: loaded
                })}
            />
            <iframe
                className={classnames({
                    [styles.htmlViewer]: true,
                    [styles.loading]: !loaded
                })}
                ref={ref}
                title={`${title}`}
                src={`${src}`}
                width="100%"
                height="100%"
                onload={() => console.log('xxx >>')}
            />
        </div>
    )
};
