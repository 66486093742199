import Header from './components/Header/Header';
import Navigation from './components/Navigation/Navigation';
import ModalOverlay from './components/ModalOverlay/ModalOverlay';
import Button from './components/Button/Button';
import { DataProvider } from './context/data-context';
import { ModalProvider } from './context/modal-context';
import MobileNavigation from './components/MobileNavigation/MobileNavigation';
import { ToastNotification } from './components/ToastNotification/ToastNotification';

export const Root = ({ children }) => {

  const collapseGX = () => {
    window.parent.postMessage('collapseGX', '*');
  }

  return (
    <DataProvider>
      <ModalProvider>
        <Header />
        <ToastNotification />
        <Navigation />
        <div className="mobile-nav">
          <MobileNavigation />
        </div>
        <ModalOverlay />
        {children}
      </ModalProvider>
      <Button text="Collapse" type="collapse" onClick={collapseGX} />
    </DataProvider>
  )
}
