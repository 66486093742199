import styles from './SearchField.module.scss';
import { IconButton } from "../../Buttons/IconButton";
import {
  ArrowRight,
  ChatBotSubmit,
  Chat,
  // Voice
} from '../../Icons';
import noop from 'lodash/noop';
import isEmpty from 'lodash/isEmpty';

export function SearchField({
  onSubmit = noop,
  // onMicClick = noop,
  onChange = noop,
  placeholder = "Let's talk",
  showIcon = true,
  value = "",
  chatBot = false,
}) {
  return (
    <div className={styles.container}>
      {showIcon && (
        <IconButton className={styles.chat} >
          <Chat />
        </IconButton>)
      }
      <div className={`${styles.controls} ${chatBot ? styles['chatbot-controls'] : ''}`}>
        <form onSubmit={(e) => {
          e.preventDefault();
          if (!isEmpty(value)) {
            onSubmit();
          }
        }}>
          <input
            className={`${styles.input} ${chatBot ? styles['chatbot-input'] : ''} `}
            type="text"
            placeholder={placeholder}
            value={value}
            onChange={({ target }) => onChange(target.value)} />
        </form>
        <div className={styles.buttons}>
          <IconButton className={styles.submit} onClick={onSubmit} disabled={isEmpty(value)} >
            {chatBot ? <ChatBotSubmit /> : <ArrowRight />}
          </IconButton>
          {/* <IconButton className={styles.voice} onClick={onMicClick} >
            <Voice />
          </IconButton> */}
        </div>
      </div>
    </div>
  )
}
