import React, { useEffect, useState, useRef } from 'react';
import styles from "./PdfViewer.module.scss";
import { Image } from "../../Image/Image";
import classnames from 'classnames';

export function PdfViewer({ src, title, thumb }) {
    const ref = useRef(null);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (ref?.current) {
            setLoaded(true);
        }
    }, [ref])

    return (
        <div className={styles.pdfViewer}>
            <Image
                src={thumb}
                className={classnames({
                    [styles.img]: true,
                    [styles.loading]: loaded
                })}
            />
            <iframe
                ref={ref}
                className={classnames({
                    [styles.iframe]: true,
                    [styles.loading]: !loaded
                })}
                type="application/pdf"
                title={`${title}`}
                src={`${src}#toolbar=0&navpanes=0`}
                width="100%"
                height="100%"
            />
        </div>
    )
};
