import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { router } from './App.js';
import {
  RouterProvider,
} from "react-router-dom";


ReactDOM.createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);