export function Bookmark({
    stroke = "#010307",
    fill = "none",
    strokeWidth = 0.6,
}) {
    return (
        <svg width="8" height="9" viewBox="0 0 8 9" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M7.50771 1.11768H1.24988L1.24988 8.15768L4.3788 6.59324L7.50771 8.15768V1.11768Z" stroke={stroke} strokeWidth={strokeWidth} />
        </svg>
    );
};
