import { PropTypes } from "prop-types";
import { useLocation } from 'react-router-dom';
import classnames from "classnames";

import styles from './SplitScreen.module.scss';

const SplitScreen = ({
  leftContent,
  rightContent,
  activeTab,
  customStyleLeft,
  rightFooterContent,
  hideRight,
}) => {
  const location = useLocation();
  return (
    <div className={styles.wrapper}>
      <div
        style={customStyleLeft}
        className={classnames({
          [styles.left]: true,
          [styles.responsiveHidden]: activeTab === 1,
        })}
      >
        {leftContent}
      </div>
      <div
        className={classnames({
          [styles.right]: !hideRight,
          [styles.hidden]: location.pathname === '/history',
          [styles.responsiveHidden]: activeTab === 0,
        })}
      >
        {rightContent}
      </div>
      <footer className={styles.footer}>
        {rightFooterContent}
      </footer>
    </div>
  )
};

SplitScreen.propTypes = {
  leftWidth: PropTypes.number,
  rightWidth: PropTypes.number,
  leftContent: PropTypes.node.isRequired,
  rightContent: PropTypes.node.isRequired,
};

SplitScreen.defaultProps = {
  leftWidth: 50,
  rightWidth: 50,
};

export default SplitScreen;