import { PropTypes } from "prop-types";
import styles from './GenericCard.module.scss';

const GenericCard = ({ title, body, onClick, index }) => {
  return (
    <div className={styles['generic-card-wrapper']} onClick={() => onClick(index)}>
      <h4>{title}</h4>
      <p>{body}</p>
    </div>
  );
};

GenericCard.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

GenericCard.defaultProps = {
  onClick: () => {},
};


export default GenericCard;