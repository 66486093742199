import { useRef } from 'react';
import styles from "./SwipeButtons.module.scss";
import { useSwipeDragHome } from '../../../hooks/useSwipeDragHome';
import { Button, IconButton } from '../../Buttons';
import { ArrowLeft, ArrowRight } from '../../Icons';

export const SwipeButtons = ({
  buttons = [],
  btnVariant = "quaternary",
  onSubmitCallback,
}) => {

  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const { position, isDragged, bind, isClick, moveLeft, moveRight, hideFeather } = useSwipeDragHome(0, containerRef, contentRef, buttons);

  const handleClick = (index) => {
    const btn = buttons[index];
    onSubmitCallback(btn.txt);
  };

  const onMouseLeave = () => {
    if (isDragged) {
      // If you need the reset functionality, you can uncomment the next line
      // reset();
    }
  };

  return (
    <div className={styles.viewPortWidth}>
      <div className={styles.container}>
        <div
          className={styles.scrollContainer}
          ref={containerRef}
          onMouseLeave={onMouseLeave}
        >
          <div
            className={styles.scroll}
            ref={contentRef}
            {...bind()}
            style={{ transform: `translateX(${position}px)` }}
          >
            {buttons.map(({ txt }, index) => (
              <Button
                onClick={() => handleClick(index)}
                variant={btnVariant}
                className={styles.btn}>
                {txt}
              </Button>
            ))}
          </div>
        </div>
        <div className={`${styles.feather} ${!hideFeather ? 'hide' : ''}`} />
        <div className={styles.controls}>
          <IconButton className={styles.icon} onClick={moveLeft}>
            <ArrowLeft />
          </IconButton>
          <IconButton className={styles.icon} onClick={moveRight}>
            <ArrowRight />
          </IconButton>
        </div>
      </div>
    </div>
  );
};
