import {
  createContext,
  useContext,
  useState,
  useMemo,
} from 'react';
import { toastNotify } from '../components/ToastNotification/ToastNotification';
import {
  SHARE_ITEM_SUCCESS
} from '../constants/toastNotificationMessages';

const DataContext = createContext();

const DataProvider = (props) => {
  const [conversationData, setConversationData] = useState([]);
  const [savedConversationContentItem, setSavedConversationContentItem] = useState([]);

  const shareSingleItem = (index, type) => {
    if (type === 'conversation') {
      setSavedConversationContentItem([{ ...conversationData[index][type], contentType: type }])
    } else {
      setSavedConversationContentItem([{ ...conversationData[0][type][index], contentType: type }])
    }

    toastNotify('success', SHARE_ITEM_SUCCESS);
  }

  const updatedSavedItems = (item, type) => {
    let alreadyExists;
    alreadyExists = savedConversationContentItem.some((savedItem) =>
      (savedItem.index === item.index && savedItem.contentType === type));

    if (!alreadyExists || !item.index) {
      setSavedConversationContentItem([...savedConversationContentItem, { ...item, contentType: type }]);
      toastNotify('success', SHARE_ITEM_SUCCESS);
    }
  };

  const removeShareItem = (id, type) => {
    let updated = [];

    savedConversationContentItem.forEach((item) => {
      if (item.id === id && item.contentType === type) return;
      updated.push(item);
    });

    setSavedConversationContentItem(updated);
  }

  const value = useMemo(() => ({
    conversationData,
    setConversationData,
    updatedSavedItems,
    savedConversationContentItem,
    removeShareItem,
    shareSingleItem,
  }), [conversationData, savedConversationContentItem]);

  return (
    <DataContext.Provider
      value={value}
      {...props}
    />
  );
};

const useData = () => useContext(DataContext);

export { DataProvider, useData };
