import styles from './Image.module.scss';
import classnames from 'classnames';

export function Image ({
  alt = "",
  className = "",
  src,
}) {
  return (
    <img
      src={src}
      className={classnames({
        [styles.img]: !className,
        [className]: className,
      })} 
      alt={alt}
    />
  )
};
